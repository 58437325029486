import { createContext, useState, useEffect, ReactNode, useContext } from "react";
import inMemoryJWT from "./inMemoryJWT";
import { Request } from "../redux/request";
import { Box, CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { IUser } from "../redux/user/user.interface";

interface AuthContextType {
    me: IUser | null,
    isUserLogged: boolean
    loggedFromAdmin: string
    SignIn?: any
    SignInAsUser?: any
    ReturnToAdmin?: any
    Logout?: any
    ChangePassword?: any
    updateMe?: any
}

export const AuthContext = createContext<AuthContextType>({
    me: null,
    isUserLogged: false,
    loggedFromAdmin: '0',
});

const AuthProvider = ({ children }: { children: ReactNode }) => {
    const [isAppReady, setIsAppReady] = useState(false);
    const [isUserLogged, setIsUserLogged] = useState(() => {
        return localStorage.getItem('isUserLogged') === 'true';
    });
    const [me, setMe] = useState<IUser | null>(null);
    const [loggedFromAdmin, setLoggedFromAdmin] = useState<string>(localStorage.getItem('loggedFrom') || '0');

    const navigate = useNavigate();

    const Logout = () => {
        Request.post('auth/logout')
            .then(res => {
                setIsUserLogged(false);
                localStorage.removeItem('isUserLogged');
                inMemoryJWT.deleteToken();
                setMe(null);
            })
            .catch(err => { console.log(err) });
    };

    const SignIn = (data: any) => {
        Request.post('auth/signin', data)
            .then(res => {
                const { accessToken, accessTokenExpiration, user } = res;
                inMemoryJWT.setToken(accessToken, accessTokenExpiration);
                setIsUserLogged(true);
                localStorage.setItem('isUserLogged', 'true');
                setMe(user);
                navigate('/admin');
            })
            .catch(err => { toast(err, { type: 'error' }) });
    };
  
  const SignInAsUser = (user_id: string, my_id: string) => {
      Request.post(`auth/signin/user/${user_id}`, {})
        .then(res => {
            const { accessToken, accessTokenExpiration, user } = res;
            inMemoryJWT.setToken(accessToken, accessTokenExpiration);
            setIsUserLogged(true);
            setLoggedFromAdmin(user_id)
            localStorage.setItem('loggedFrom', my_id)
            setMe(user)
            navigate('/admin')
        })
        .catch(err => {
            console.log(err)
            toast(err, { type: 'error' })
        })
  };
 
  const ReturnToAdmin = () => {
      const user_id = localStorage.getItem('loggedFrom')
    Request.post(`auth/signin/admin/${user_id}`, {})
        .then(res => {
            const { accessToken, accessTokenExpiration, user } = res;
            inMemoryJWT.setToken(accessToken, accessTokenExpiration);
            setIsUserLogged(true);
            setLoggedFromAdmin('0')
            localStorage.setItem('loggedFrom', '0')
            setMe(user)
            navigate('/admin')
        })
        .catch(err => {
            console.log(err)
            toast(err, { type: 'error' })
        })
  };
  
  const ChangePassword = (data: any) => {
      Request.post('auth/password', data)
        .then(res => {
            toast('The password has been successfully changed', { type: 'success' })
        })
        .catch(err => { toast(err, { type: 'error' }) })
  };
  
  const updateMe = (data: any) => {
      Request.post('auth/update', data)
        .then(res => {
            const { user } = res
            setMe(user)
        })
        .catch(err => { toast(err, { type: 'error' }) })
  };

    useEffect(() => {
        Request.post('auth/refresh')
            .then(res => {
                const { accessToken, accessTokenExpiration, user } = res;
                inMemoryJWT.setToken(accessToken, accessTokenExpiration);
                setIsAppReady(true);
                setIsUserLogged(true);
                localStorage.setItem('isUserLogged', 'true');
                setMe(user);
            })
            .catch(err => {
                setIsAppReady(true);
                setIsUserLogged(false);
                localStorage.removeItem('isUserLogged');
            });
    }, []);

  useEffect(() => {
    const handlePersistedLogOut = (event: any) => {
      if (event.key === 'logout') {
        inMemoryJWT.deleteToken();
        setIsUserLogged(false);
      }
  };

  window.addEventListener("storage", handlePersistedLogOut);

  return () => {
    window.removeEventListener("storage", handlePersistedLogOut);
  };
  }, []);

    return (
        <AuthContext.Provider
            value={{
                me,
                SignIn,
                Logout,
                ChangePassword,
                updateMe,
                SignInAsUser,
                ReturnToAdmin,
                loggedFromAdmin,
                isUserLogged,
            }}
        >
            {isAppReady ? (
                children
            ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 80px)' }}><CircularProgress /></Box>
            )}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext)

export default AuthProvider;
