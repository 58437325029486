import { Box, Button, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { IContact, updateContact } from "../../../redux/slices/contacts"
import { useDispatch } from "../../../redux/store"

type FieldType = {
    key: string
    value: string
    is_upd: boolean
    can_upd_key: boolean
}

export const Details = ({ contact }: { contact: IContact }) => {
    const [fields, setFields] = useState<FieldType[]>([])
    const [notes, setNotes] = useState('')
    const dispatch = useDispatch()

    useEffect(() => {
        setFields(contact.fields.map(it => ({ key: it.key, value: it.value, is_upd: false, can_upd_key: false })))
        setNotes(contact.notes)
    }, [contact])

    const onSubmit = () => {
        const contact_fields = fields.filter(it => it.is_upd && it.key).map(it => ({ key: it.key, value: it.value, contact_number: contact.number }))
        console.log(contact_fields)
        if(!contact_fields.length && !notes) return
        dispatch(updateContact({ id: contact.number, payload: { fields: contact_fields, notes } }))
        setFields([])
        setNotes('')
    }

    return (
        <div className="ttteee" style={{ height: '100%', position: 'relative', bottom: -60, overflowY: 'auto' }}>
            <div className="chat__title">Name</div>

            <Typography>Contact Data</Typography>
            {
                fields.map((field, index) => 
                    <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 10, gap: 4 }}>
                        {field.can_upd_key 
                        ? <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
                            <TextField value={field.key} onChange={e => { setFields(prev => prev.map((it, i) => index === i ? { ...it, key: e.target.value, is_upd: true } : it)) }}></TextField>
                            {!field.key && <Typography style={{ alignSelf: 'flex-end', position: 'absolute', top: 56 }} variant="caption" color={'red'}>field is required</Typography>}
                        </Box>
                        : <Typography>{field.key}</Typography>}
                        <TextField value={field.value} onChange={e => { setFields(prev => prev.map((it, i) => index === i ? { ...it, value: e.target.value, is_upd: true } : it)) }}></TextField>
                    </div>    
                )
            }
            <Button style={{ marginTop: 30 }} variant="contained" onClick={() => setFields(prev => [...prev, { key: '', value: '', is_upd: false, can_upd_key: true }])}>Add new field</Button>

            <Typography style={{ marginTop: 30 }}>Contact Notes</Typography>
            <TextField
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                style={{ marginTop: 20 }}
                fullWidth
                placeholder="Contact Notes"
                multiline
                rows={4}
            />

            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={onSubmit} size="large" style={{ marginTop: 20, marginBottom: 30 }} variant="contained">Update</Button>
            </Box>
        </div>
    )
}
