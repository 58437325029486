import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import './App.scss';
import { AppRoutes } from './routes';
import ThemeProvider, { useTheme } from './services/theme';
import AuthProvider from './services/authContext';
import {HashRouter} from "react-router-dom";
import RouteTracker from "./route-tracker";

function App() {//BUILD_PATH='../server/build'

  return (
      <ThemeProvider>
          <div className='app'>
            <AuthProvider>
                <RouteTracker />
                <AppRoutes />
            </AuthProvider>
            <ToastContainer />
          </div>
      </ThemeProvider>
  );
}

export default App;
