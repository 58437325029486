import { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    Typography,
    Box,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Container,
    Button,
    Stack,
    Modal,
    InputLabel,
    TextField,
    Divider,
    Select,
    FormControl,
    MenuItem,
    SelectChangeEvent,
    Switch,
    Tooltip
} from "@mui/material"
import { useDispatch, useSelector } from "../../../../redux/store";
import { Colors } from "../../../../types";
import { useTheme } from "../../../../services/theme";
import { createServer, getServer, updateServer } from "../../../../redux/slices/server";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

export const SendingServersAdd = () => {
    const { server } = useSelector(store => store.server)
    const [name, setName] = useState('');
    const [link, setLink] = useState('')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const [sendingLimit, setSendingLimit] = useState(0)
    const [timeBase, setTimeBase] = useState(0)
    const [timeUnit, setTimeUnit] = useState('')

    const [plain, setPlain] = useState(false)
    const [schedule, setSchedule] = useState(false)
    const [mms, setMms] = useState(false)
    const [analysis, setAnalysis] = useState(false)
    const [testDev, setTestDev] = useState(false)

    const dispatch = useDispatch()
    const { id } = useParams()

    useEffect(() => {
        if(id) {
            dispatch(getServer(id))
        }
    }, [id])

    useEffect(() => {
        if(id && server) {
            setName(server.name)
            setLink(server.link)
            setUsername(server.username)
            setPassword(server.password)
            setSendingLimit(server.sending_limit)
            setTimeBase(server.time_base)
            setTimeUnit(server.time_unit)
            setPlain(server.is_plain)
            setSchedule(server.is_shedule)
            setMms(server.is_mms)
            setAnalysis(server.is_analysis)
            setTestDev(server.is_test)
        }
    }, [server])
 
    const onSubmit = () => {
        const regex = /http.*?:\d*?$/g
        if(!regex.exec(link)) {
            toast('link should end with port', { type: 'error' })
            return
        }

        if(id) {
            dispatch(updateServer({ id, payload: {
                name, link, username, password, 
                sending_limit: sendingLimit, time_base: timeBase, time_unit: timeUnit,
                is_plain: plain, is_schedule: schedule, is_mms: mms, is_analysis: analysis, is_test: testDev
            } }))
        } else {
            dispatch(createServer({
                name, link, username, password, 
                sending_limit: sendingLimit, time_base: timeBase, time_unit: timeUnit,
                is_plain: plain, is_schedule: schedule, is_mms: mms, is_analysis: analysis, is_test: testDev
            }))
        }
    }

    const theme = useTheme()

    return (
        <div>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                <Typography variant="h5">Workspace</Typography><span> /sending servers/ add</span>
            </Box>
                
            <Box sx={{ 
                    backgroundColor: theme === 'dark' ? Colors.MAIN_DARK : Colors.MAIN_WHITE, 
                    padding: 2, borderRadius: 2, marginTop: 2, display: 'flex', flexDirection: 'column', gap: 1
                }}
            >
                <Typography variant="subtitle1" marginBottom={1}>Credentials</Typography>

                <TextField value={name} onChange={e => setName(e.target.value)} placeholder="Name" fullWidth></TextField>
                <TextField value={link} onChange={e => setLink(e.target.value)} placeholder="API Link" fullWidth></TextField>
                <TextField value={username} onChange={e => setUsername(e.target.value)} placeholder="User name" fullWidth></TextField>
                <TextField value={password} onChange={e => setPassword(e.target.value)} placeholder="Password" fullWidth></TextField>
            </Box>
            
            <Box sx={{ 
                    backgroundColor: theme === 'dark' ? Colors.MAIN_DARK : Colors.MAIN_WHITE, 
                    padding: 2, borderRadius: 2, marginTop: 2, display: 'flex', flexDirection: 'column', gap: 1
                }}
            >
                <Typography variant="subtitle1" marginBottom={1}>Sending limit</Typography>
                <Typography variant="caption" marginBottom={1}>
                    The configuration setting below allows you to set a limit on sms sending speed. For example, to limit
                    sending speed to <span style={{ color: 'red' }}>500 sms every 5 minutes</span>, you can set 
                    <span style={{ color: 'red' }}> Sending limit = 500, Time value = 5, and Time unit = minute</span> accordingly.
                    Also set <span style={{ color: 'red' }}>amount</span> of <span style={{ color: 'red' }}>several messages</span> with a <span style={{ color: 'red' }}>single HTTP request</span>.
                </Typography>

                {/* <TextField type="number" value={sendingLimit} onChange={e => setSendingLimit(+e.target.value)} placeholder="Sending limit" fullWidth></TextField> */}
                <TextField type="number" value={timeBase} onChange={e => setTimeBase(+e.target.value)} placeholder="Time Base" fullWidth></TextField>
                {/* <TextField value={timeUnit} onChange={e => setTimeUnit(e.target.value)} placeholder="Time Unit" fullWidth></TextField> */}
                <FormControl style={{ marginTop: 10, width: '100%' }}>
                        <InputLabel sx={{ color: Colors.MAIN_LIGTH }} id="demo-simple-select-label">Select campaign type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={timeUnit}
                            label="Select campaign type"
                            onChange={(e) => setTimeUnit(e.target.value)}
                        >
                            <MenuItem value={'sec'}>Second</MenuItem>
                            <MenuItem value={'min'}>Minute</MenuItem>
                            <MenuItem value={'hr'}>Hour</MenuItem>
                        </Select>
                   </FormControl>
            </Box>
            
            <Box sx={{ 
                    backgroundColor: theme === 'dark' ? Colors.MAIN_DARK : Colors.MAIN_WHITE, 
                    padding: 2, borderRadius: 2, marginTop: 2, display: 'flex', flexDirection: 'column', gap: 1
                }}
            >
                <Typography variant="subtitle1" marginBottom={1}>Available Features</Typography>

                <Box sx={{ display: 'flex' }}>

                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <span style={{ alignSelf: 'center' }}>Plain</span> <Switch checked={plain} onChange={e => setPlain(e.target.checked)}></Switch>
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <span style={{ alignSelf: 'center' }}>MMS</span> <Switch checked={mms} onChange={e => setMms(e.target.checked)}></Switch>
                    </Box>

                    <Tooltip title="This parameter includes the server in the list of sent message analytics" arrow placement="top">
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <span style={{ alignSelf: 'center' }}>Analysis</span> <Switch checked={analysis} onChange={e => setAnalysis(e.target.checked)}></Switch>
                        </Box>
                    </Tooltip>

                    <Tooltip title="When this option is enabled, the server will participate in analytics only as a test site for receiving and processing messages" arrow placement="top">
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <span style={{ alignSelf: 'center' }}>Test</span> <Switch checked={testDev} onChange={e => setTestDev(e.target.checked)}></Switch>
                        </Box>
                    </Tooltip>

                </Box>

                <Button onClick={onSubmit} variant="contained" style={{ width: 100 }}>Save</Button>
            </Box>
        </div>
    )
}
