import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Typography } from "@mui/material"
import { Colors } from "../../../../types"
import { useTheme } from '../../../../services/theme'
import { useDispatch } from '../../../../redux/store'
import {ICampaign} from "../../../../redux/campaign/campaign.interface";
import {updateCampaignUser} from "../../../../redux/campaign/campaign.request";

export const CampaignStaff = ({ campaign }: { campaign: ICampaign }) => {
    const dispatch = useDispatch()
    const theme = useTheme()

    const updateUser = (id: string, is_added: boolean) => {
        dispatch(updateCampaignUser({ user_id: id, payload: { campaign_id: campaign?.id, is_added } }))
    }

    return (
        <Box 
            sx={{ 
                backgroundColor: theme === 'dark' ? Colors.MAIN_DARK : Colors.MAIN_WHITE,
                marginTop: 1,
                padding: 2,
            }}
        >
            <Typography variant="subtitle1">CAMPAIGN PERMISSIONS</Typography>
            <Typography variant="caption">Add and remove workspace users from claiming or being assigned new contacts from this campaign</Typography>
        
            {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box>
                    <Typography style={{ minWidth: 'fit-content', marginBottom: 4, whiteSpace: 'nowrap', marginRight: 10 }}>User Groups</Typography>
                    <Typography variant="subtitle2">None</Typography>
                </Box>

                <FormControl style={{ marginTop: 10, width: '100%', }}>
                    <InputLabel sx={{ color: Colors.MAIN_LIGTH, }} id="demo-simple-select-label">Select User Group</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select User Group"
                    >
                        <MenuItem value={'Test'}>Test</MenuItem>
                        <MenuItem value={'Test2'}>Test2</MenuItem>
                    </Select>
                </FormControl>
            </Box> */}

            <Typography style={{ marginTop: 10, marginBottom: 6 }}>Added Users</Typography>
            <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                {campaign.users.filter(user => user.is_added).map(user => 
                <Box
                    key={user.user_id}
                    sx={{
                        padding: '4px',
                        backgroundColor: 'green',
                        whiteSpace: 'nowrap',
                        fontSize: '12px',
                        borderRadius: '4px'
                    }}
                >
                    {user.username} <FontAwesomeIcon onClick={() => updateUser(user.user_id, false)} color="red" style={{ cursor: 'pointer', marginLeft: 6 }} icon={"fa-solid fa-x".split(' ') as any}></FontAwesomeIcon>
                </Box>)}
            </Box>


            <Typography style={{ marginTop: 20, marginBottom: 6 }}>Removed Users</Typography>
            <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                {campaign.users.filter(user => !user.is_added).map(user => 
                <Box 
                    sx={{
                        padding: '4px',
                        backgroundColor: 'gray',
                        whiteSpace: 'nowrap',
                        fontSize: '12px',
                        borderRadius: '4px',
                        color: 'white'
                    }}
                >
                    {user.username} <FontAwesomeIcon onClick={() => updateUser(user.user_id, true)} color="white" style={{ cursor: 'pointer', marginLeft: 6 }} icon={"fa-solid fa-plus".split(' ') as any}></FontAwesomeIcon>
                </Box>)}
            </Box>
        </Box>
    )
}
