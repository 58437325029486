import { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography, Box, TableContainer, Table, TableHead, TableRow, TableCell, 
    TableBody, Paper, Container, Button, Stack, Modal, TextField } from "@mui/material"
import { useDispatch, useSelector } from "../../../../redux/store";
import { useNavigate } from "react-router-dom";
import { IResponse, clearResponce, clearResponces, createResponse, deleteResponse, getResponseUser, updateResponse } from "../../../../redux/slices/responses";
import { KeyWords } from "../../../../types";
import {useAuth} from "../../../../services/authContext";

export const Responses = () => {
    const { responsesUser } = useSelector(store => store.responses)
    const [openModal, setOpenModal] = useState<IResponse | boolean>(false);
    const [horizontalScroll, setHorizontalScroll] = useState(0)

    const { me } = useAuth()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getResponseUser({id: me?.id}))
        return () => { dispatch(clearResponces()) }
    }, [])

    const onDelete = (id: string) => {
        dispatch(deleteResponse(id))
    }
    
    const handleScroll = useCallback((event: any) => {
        const { scrollTop, scrollHeight, clientHeight, scrollLeft } = event.target;
        const isAtBottom = scrollTop + clientHeight >= scrollHeight;
    
        if(scrollLeft !== horizontalScroll) {
            setHorizontalScroll(scrollLeft)
            return
        }

        if (isAtBottom) {
            dispatch(getResponseUser({id: me?.id}))
        }
    }, [horizontalScroll])

    return (
        <div>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                <Typography variant="h5">Workspace</Typography><span> /quick responses</span>
            </Box>

            <Container sx={{ display: 'flex', marginTop: 4, gap: 2 }}>
               <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <Button onClick={() => setOpenModal(true)} size="medium" variant="contained">Add New Response</Button>
               </Stack>
            </Container>

            <TableContainer onScroll={handleScroll} style={{ marginTop: 30, maxHeight: 'calc(100vh - 300px)' }} component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                    <TableRow>
                        <TableCell>NAME</TableCell>
                        <TableCell style={{ minWidth: 300, maxWidth: '50%' }}>TEXT</TableCell>
                        <TableCell>ACTIONS</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {responsesUser.map((response) => (
                        <TableRow
                            key={response.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">{response.name} </TableCell>
                            <TableCell>{response.text}</TableCell>
                            <TableCell>
                                <Box sx={{display: 'flex', gap: 3, alignItems: 'center' }}>
                                    {<FontAwesomeIcon onClick={() => setOpenModal(response)} style={{ cursor: 'pointer' }} color="green" icon={"fa-solid fa-pen-to-square".split(' ') as any}></FontAwesomeIcon>}
                                    {<FontAwesomeIcon onClick={() => onDelete(response.id)} style={{ cursor: 'pointer' }} color="red" icon={"fa-solid fa-x".split(' ') as any}></FontAwesomeIcon>}
                                </Box>
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <ModalContent response={openModal} close={() => setOpenModal(false)}></ModalContent>
        </div>
    )
}

const ModalContent = ({ response, close }: { response: IResponse | boolean, close: () => void }) => {
    const [name, setName] = useState('')
    const [text, setText] = useState('')
    const { me } = useAuth()
    const dispatch = useDispatch()

    useEffect(() => {
        if (response && typeof response !== 'boolean') {
            setName(response.name)
            setText(response.text)
        }

        return () => { dispatch(clearResponce()) }
    }, [response])

    const onSubmit = () => {
        if(!name || !text) { console.log('err'); return 'err' }
     
        if (response && typeof response !== 'boolean') {
            dispatch(updateResponse({ id: response.id, payload: { text, name } }))
        } else {
            dispatch(createResponse({ name, text, user_id: me?.id }))
        }
    }

    const onAddCustomText = (text: string) => {
        setText(prev => prev + ' ' + text)
    }

    return (
        <Modal
            open={!!response}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box 
                sx={{ 
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '50%',
                    bgcolor: 'background.paper',
                    color: 'text.primary',
                    boxShadow: 24,
                    p: 4, 
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Add Quick Response
                    </Typography>
                    <div style={{ cursor: 'pointer' }} onClick={close}>
                        {<FontAwesomeIcon icon={"fa-solid fa-x".split(' ') as any}></FontAwesomeIcon>}
                    </div>
                </Box>   

                <TextField
                    value={name}
                    onChange={e => setName(e.target.value)}
                    style={{ marginTop: 20 }} 
                    fullWidth 
                    placeholder="Response Name"
                />

                <TextField
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    style={{ marginTop: 20 }}
                    fullWidth
                    placeholder="Response Message"
                    multiline
                    rows={4}
                />    

                <Button onClick={() => { onAddCustomText(`{@Pick( | | )}`) }} style={{ marginTop: 10, marginRight: 6 }} variant="contained">PICK</Button>
                {(Object.keys(KeyWords) as Array<keyof typeof KeyWords>).map(it => 
                    <Button onClick={() => onAddCustomText(`{${KeyWords[it]}}`,)} style={{ marginTop: 10, marginRight: 6 }} variant="contained">{KeyWords[it]}</Button>
                )}

                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={onSubmit} size="large" style={{ marginTop: 20 }} variant="contained">{response && typeof response !== 'boolean' ? 'Update' : 'Create'}</Button>
                </Box>       
            </Box>
        </Modal>
    )
}
