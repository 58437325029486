import { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography, Box, TableContainer, Table, TableHead, TableRow, TableCell, 
    TableBody, Paper, Container, Button, Stack, Modal, InputLabel, TextField, Divider, Select, FormControl, MenuItem, SelectChangeEvent, Switch, Chip, OutlinedInput } from "@mui/material"
import { useDispatch, useSelector } from "../../../../redux/store";
import { Colors, KeyWordsTypes } from "../../../../types";
import { IKeyword, createKeyword, deleteKeyword, getKeywords, updateKeyword } from "../../../../redux/slices/keywords";
import { getLists } from "../../../../redux/slices/list";
import {getUsers} from "../../../../redux/user/user.request";

export const Keywords = () => {
    const [isOpenModal, setIsOpenModal] = useState<IKeyword | boolean>(false)
    const [horizontalScroll, setHorizontalScroll] = useState(0)
    const { keywords } = useSelector(store => store.keywords)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getKeywords())
    }, [])

    const handleScroll = useCallback((event: any) => {
        const { scrollTop, scrollHeight, clientHeight, scrollLeft } = event.target;
        const isAtBottom = scrollTop + clientHeight >= scrollHeight;
    
        if(scrollLeft !== horizontalScroll) {
            setHorizontalScroll(scrollLeft)
            return
        }

        if (isAtBottom) {
            dispatch(getUsers())
        }
    }, [horizontalScroll])

    const handleDelete = (id: string) => {
        dispatch(deleteKeyword(id))
    }

    const handleChangeStatus = (id: string, status: boolean) => {
        dispatch(updateKeyword({ id, payload: { status: !status } }))
    }

    return (
        <div>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h5">Workspace</Typography><span> /keywords</span>
            </Box>

            <Container sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 4 }}>
               <Box>
                    <Typography variant="h6">Keywords</Typography>
                    <Typography sx={{ fontSize: 12 }} variant="subtitle2">Manage your keywords here</Typography>
               </Box>
               <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <Button onClick={() => setIsOpenModal(true)} size="small" variant="contained">Create Keyword</Button>
               </Stack>
            </Container>

            <TableContainer onScroll={handleScroll} style={{ marginTop: 30, maxHeight: 400 }} component={Paper}>
                <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }}>
                    <TableHead>
                    <TableRow>
                        <TableCell>TITLE</TableCell>
                        <TableCell>KEYWORD</TableCell>
                        <TableCell>STATUS</TableCell>
                        <TableCell>TYPE</TableCell>
                        <TableCell>ACTIONS</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {keywords.map((keyword) => (
                        <TableRow
                            key={keyword.title}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">{keyword.title} </TableCell>
                            <TableCell>{keyword.text}</TableCell>
                            <TableCell><Switch checked={keyword.status} onChange={e => handleChangeStatus(keyword.id, keyword.status)}></Switch></TableCell>
                            <TableCell>{KeyWordsTypes[keyword.type]}</TableCell>
                            <TableCell>
                                {
                                 keyword.lists && keyword.lists.map(list => <div>{list.name}</div>)
                                }
                                
                            </TableCell>
                            <TableCell>
                               <Box sx={{ display: 'flex', gap: 1 }}>
                                    <div style={{ cursor: 'pointer' }} onClick={() => handleDelete(keyword.id)}>
                                        {<FontAwesomeIcon color="red" icon={"fa-solid fa-trash".split(' ') as any}></FontAwesomeIcon>}
                                    </div>
                                    <div style={{ cursor: 'pointer' }} onClick={() => setIsOpenModal(keyword)}>
                                        {<FontAwesomeIcon color="skyblue" icon={"fa-solid fa-pen-to-square".split(' ') as any}></FontAwesomeIcon>}
                                    </div>
                               </Box>
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Modal 
                open={!!isOpenModal} onClose={() => setIsOpenModal(false)} 
                aria-labelledby="modal-modal-title" 
                aria-describedby="modal-modal-description" >
                    <ModalContent keyword={isOpenModal} close={() => setIsOpenModal(false)}></ModalContent>
            </Modal>
        </div>
    )
}

const ModalContent = ({ keyword, close }: { keyword: IKeyword | boolean, close: () => void }) => {
    const [title, setTitle] = useState('');
    const [type, setType] = useState('')
    const [text, setText] = useState('')
    const [selectedActions, setSelectedActions] = useState<any | null>([])
    const { lists } = useSelector(store => store.list)
    const dispatch = useDispatch()

    const handleSave = () => {
        if(!type || !title || !selectedActions?.length) return
        if (keyword && typeof keyword !== 'boolean') {
            dispatch(updateKeyword({ id: keyword.id, payload: { text, title, type, actions: selectedActions } }))
        } else {
            dispatch(createKeyword({ text, title, type, actions: selectedActions }))
        }
    }

    useEffect(() => {
        if (keyword && typeof keyword !== 'boolean') {
            setTitle(keyword.title)
            setText(keyword.text)
            setType(keyword.type)
            setSelectedActions(keyword.lists.map(list => list.id))
        }

        dispatch(getLists())

        // return () => { dispatch(clearTemplate()) }
    }, [])

    return (
        <Box 
            sx={{ 
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '50%',
                bgcolor: 'background.paper',
                color: 'text.primary',
                boxShadow: 24,
                p: 4, 
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', /*alignItems: 'center'*/ }}>
                <Typography variant="h6">{keyword && typeof keyword !== 'boolean' ? 'Update' : 'Create'} keyword</Typography>
                <Box style={{ cursor: 'pointer' }} onClick={close}>
                    {<FontAwesomeIcon icon={"fa-solid fa-x".split(' ') as any}></FontAwesomeIcon>}
                </Box>
            </Box>
            <Divider style={{ marginTop: 10 }}></Divider>

            <TextField value={title} onChange={e => setTitle(e.target.value)} style={{ marginTop: 30 }} placeholder="Title" fullWidth></TextField>

            <FormControl style={{ marginTop: 10, width: '100%', }}>
                <InputLabel sx={{ color: Colors.MAIN_LIGTH, }} id="demo-simple-select-label">If inbound message</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="If inbound message"
                    value={type}
                    onChange={e => setType(e.target.value)}
                >
                    {(Object.keys(KeyWordsTypes) as Array<keyof typeof KeyWordsTypes>).map(it => 
                        <MenuItem value={it}>{KeyWordsTypes[it]}</MenuItem>
                    )}
                </Select>
            </FormControl>

            <TextField value={text} onChange={e => setText(e.target.value)} style={{ marginTop: 10 }} placeholder="Text" fullWidth></TextField>

            <MultiSelectKeywordActions
                array={lists}
                setSelectedActions={setSelectedActions}
                selected={selectedActions}
                setSelected={setSelectedActions}
            ></MultiSelectKeywordActions>

            <Box sx={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={handleSave} size="large" variant="contained">Save</Button>
            </Box>
        </Box>
    )
}

const MultiSelectKeywordActions = ({ array, selected, setSelected, setSelectedActions }: { array: any[], selected: any[], setSelected: any, setSelectedActions: any }) => {
 
    return (
      <FormControl sx={{ marginTop: 2, width: '100%' }}>
        <InputLabel>Multiple Select</InputLabel>
        <Select
          multiple
          value={selected}
          onChange={(e: any) => {console.log(e.target.value); setSelected(e.target.value)}}
          input={<OutlinedInput label="Multiple Select" />}
          renderValue={(selected) => (
            <Stack gap={1} direction="row" flexWrap="wrap">
              {selected.map((value: any) => (
                <Chip
                  key={value.id}
                  label={<div onClick={() => setSelectedActions(array.find(it => it.id === value))}>{array.find(it => it.id === value)?.name}</div>}
                />
              ))}
            </Stack>
          )}
        >
          {array.map((item, n) => (
            <MenuItem
              key={item.id}
              value={item.id}
              sx={{ justifyContent: "space-between" }}
            >
              {item.name}
              {selected?.includes(item.id) ? <FontAwesomeIcon color="green" icon={"fa-solid fa-check".split(' ') as any}></FontAwesomeIcon> : null}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
};