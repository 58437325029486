import {Box, Button, CircularProgress, Tooltip, Typography} from "@mui/material";
import { Colors } from "../../../../types";
import { useTheme } from "../../../../services/theme";
import { PieChart } from '@mui/x-charts/PieChart';
import {useSelector} from "../../../../redux/store";
import {IDetailsMobileOperator} from "../../../../redux/campaign/campaign.interface";
import Loader from "../../../../components/Loader/Loader";

type Props = {
    listDetails: IDetailsMobileOperator[],
    removeList: (dip_carrier: string) => void
    recoverList: (dip_carrier: string) => void
}

const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

const staticColors = [
    '#FF6633', '#b2e80d', '#FF33FF', '#007bf6', '#00B3E6',
    '#E6B333', '#1d3b88', '#6b10b6', '#99FF99', '#B34D4D',
    '#80B300', '#809900', '#ff0000', '#6680B3', '#66991A'
];

export const Details = ({ listDetails, removeList, recoverList }: Props) => {
    const theme = useTheme();
    const { isLoadingRemoveList, isLoadingGetDetails } = useSelector(store => store.campaign)

    const chartData = listDetails.map((detail, index) => {
        const carrier_groups = (detail.carrier_groups === 'Landline') ? `${detail.carrier_groups} / disabled` : detail.carrier_groups;
        const landline = (detail.carrier_groups === 'Landline') ? true : false;

        return {
            id: index,
            value: parseInt(detail.total, 10),
            label: carrier_groups + ` - (${detail.total})`,
            dip_carrier: detail.dip_carrier,
            deleted: detail.deleted,
            color: staticColors[index % staticColors.length],
            landline
        }
    });

    return (
        <Box
            sx={{
                backgroundColor: theme === 'dark' ? Colors.MAIN_DARK : Colors.MAIN_WHITE,
                marginTop: 1,
                padding: 2,
                height: 400,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >

            <Typography variant="subtitle1">LIST DETAILS</Typography>
            <Typography variant="caption">Downloaded contacts settings</Typography>

            {(listDetails.length > 0) ? (
                <>
                    <PieChart
                        series={[
                            {
                                data: chartData,
                                innerRadius: 30,
                                outerRadius: 100,
                                paddingAngle: 5,
                                cornerRadius: 5,
                                startAngle: -90,
                                endAngle: 270
                            }
                        ]}
                        sx={{
                            '.MuiChartsLegend-column': {
                                display: 'none'
                            }
                        }}
                    />
                    <Box sx={{ marginTop: 2 }}>
                        {chartData.map((dataPoint) => (
                            <Typography key={`label-${dataPoint.id}`} variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ color: dataPoint.color }}>■</span>
                                <span style={{ marginRight: 'auto', marginLeft: 8 }}>{dataPoint.label}</span>

                                {dataPoint.deleted ? (
                                    (!dataPoint.landline && (!isLoadingRemoveList && !isLoadingGetDetails)) ? (
                                        <span
                                            onClick={() => recoverList(dataPoint.dip_carrier)}
                                            style={{ color: 'green', cursor: 'pointer', marginLeft: 8 }}
                                        >Recover</span>
                                    ) : ( isLoadingRemoveList  || isLoadingGetDetails ) && (
                                        <span
                                            style={{color: 'silver', cursor: 'wait', marginLeft: 8}}
                                        >Recover</span>
                                    )
                                ):(
                                    (!dataPoint.landline && (!isLoadingRemoveList && !isLoadingGetDetails)) ? (
                                        <span
                                            onClick={() => removeList(dataPoint.dip_carrier)}
                                            style={{ color: 'red', cursor: 'pointer', marginLeft: 8 }}
                                        >Remove</span>
                                    ) : ( isLoadingRemoveList  || isLoadingGetDetails ) && (
                                        <span
                                            style={{color: 'silver', cursor: 'wait', marginLeft: 8}}
                                        >Remove</span>
                                    )
                                )}


                            </Typography>
                        ))}
                    </Box>
                </>
            ) : (
                (isLoadingGetDetails ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress />
                    </div>
                )
                :
                (
                    <Typography variant="body1">No Details Available</Typography>
                ))

            )}

        </Box>
    );
}