import { createSlice, createAsyncThunk, PayloadAction, current } from "@reduxjs/toolkit";
import { Request } from "../request";
import { toast } from "react-toastify";
import { IServer } from "./server";

export interface IGroup {
    id: string
    name: string
    status: boolean
    servers: IServer[]
    createdAt: string;
}

type State = {
  group: IGroup | null;
  groups: IGroup[];
  limit: number;
  offset: number;
  isLoading: boolean;
};

const initialState: State = {
  group: null,
  groups: [],
  limit: 8,
  offset: 0,
  isLoading: false,
};


export const getGroup = createAsyncThunk(
  "/groups/get",
  async (id: string, { rejectWithValue }) => {
    try {
        const response = await Request.get(`groups/${id}`)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getGroups = createAsyncThunk(
  "/groups/getAll",
  async (_, { getState, rejectWithValue }: any) => {
    try {
        const response = await Request.get(`groups`)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const createGroup = createAsyncThunk(
  "/groups/create",
  async (payload: any, { rejectWithValue }) => {
    try {
        const response = await Request.post('groups', payload)
        toast('created', { type: 'success' })
        return response
    } catch (error: any) {
        toast('some error', { type: 'error' })
      return rejectWithValue(error);
    }
  }
);

export const updateGroup = createAsyncThunk(
  "/groups/update",
  async ({ id, payload }: any, { rejectWithValue }) => {
    try {
        const response = await Request.post(`groups/${id}`, payload)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const deleteGroup = createAsyncThunk(
  "/groups/delete",
  async (id: string, { rejectWithValue }) => {
    try {
        const response = await Request.delete(`groups/${id}`)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);


const groupSlice = createSlice({
  name: "groups",
  initialState,
  reducers: { 
    clearGroup: (state) => {
        state.group = null
    },
    clearGroups: (state) => {
        state.groups = []
        state.offset = 0
    }
  },
  extraReducers: (builder) => {
    builder
      // get
      .addMatcher(
        (action) => action.type === getGroup.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === getGroup.fulfilled.type,
        (state, action: PayloadAction<{group: IGroup}>) => {
          state.isLoading = false;
          state.group = action.payload.group
        }
      )
      .addMatcher(
        (action) => action.type === getGroup.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
      // get all
      .addMatcher(
        (action) => action.type === getGroups.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === getGroups.fulfilled.type,
        (state, action: PayloadAction<{groups: IGroup[]}>) => {
            state.groups = [...current(state.groups), ...action.payload.groups]
            // state.offset = current(state).offset + action.payload.groups.length || current(state).limit

            const totalLength = current(state).offset + action.payload.groups.length + 1;
            let offset = current(state).offset + action.payload.groups.length + 1;
            if (offset > totalLength)
                offset = totalLength;

            state.offset = offset || current(state).limit

            state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === getGroups.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
      // create
      .addMatcher(
        (action) => action.type === createGroup.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === createGroup.fulfilled.type,
        (state, action: PayloadAction<{group: IGroup}>) => {
            state.groups = [...current(state.groups), action.payload.group]
            state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === createGroup.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
      // update
      .addMatcher(
        (action) => action.type === updateGroup.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === updateGroup.fulfilled.type,
        (state, action: PayloadAction<{group: IGroup}>) => {
            state.groups = current(state).groups.map(group => group.id === action.payload.group.id ? action.payload.group : group)
            state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === updateGroup.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
      // delete
      .addMatcher(
        (action) => action.type === deleteGroup.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === deleteGroup.fulfilled.type,
        (state, action: PayloadAction<{id: string}>) => {
            state.groups = current(state).groups.filter(group => group.id !== action.payload.id)
            state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === deleteGroup.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
  },
});

export const { clearGroup, clearGroups } = groupSlice.actions;

export default groupSlice.reducer;
