import {createSlice, current, PayloadAction} from "@reduxjs/toolkit";
import {IUser} from "./user.interface";
import {
    addUserServer,
    createUser, deleteUser,
    getManagerUsers,
    getStaff,
    getUser,
    getUsers,
    removeUserServer,
    updateUser
} from "./user.request";

export type UserState = {
    user: IUser | null;
    users: IUser[];
    manager_users: IUser[];
    staff: IUser[];
    limit: number;
    offset: number;
    isLoading: boolean;
};

const initialState: UserState = {
    user: null,
    users: [],
    manager_users: [],
    staff: [],
    limit: 8,
    offset: 0,
    isLoading: false,
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        clearUser: (state) => {
            state.user = null
        },
        clearUsers: (state) => {
            state.users = []
            state.offset = 0
        },
        clearStaff: (state) => {
            state.staff = []
        }
    },
    extraReducers: (builder) => {
        builder
            // get
            .addMatcher(
                (action) => action.type === getUser.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === getUser.fulfilled.type,
                (state, action: PayloadAction<{ user: IUser }>) => {
                    state.isLoading = false;
                    state.user = action.payload.user
                }
            )
            .addMatcher(
                (action) => action.type === getUser.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // get all
            .addMatcher(
                (action) => action.type === getUsers.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === getUsers.fulfilled.type,
                (state, action: PayloadAction<{users: IUser[]}>) => {
                    const currentUsers = current(state.users);
                    const newUsers = action.payload.users;

                    const usersMap = new Map();

                    [...currentUsers, ...newUsers].forEach((user) => {
                        usersMap.set(user.id, user);
                    });

                    state.users = Array.from(usersMap.values());
                    // console.log(state.users)
                    // state.offset = current(state).offset + action.payload.users.length || current(state).limit
                    const totalLength = current(state).offset + action.payload.users.length + 1;
                    let offset = current(state).offset + action.payload.users.length + 1;
                    if (offset > totalLength)
                        offset = totalLength;

                    state.offset = offset || current(state).limit

                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type === getUsers.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // create
            .addMatcher(
                (action) => action.type === createUser.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === createUser.fulfilled.type,
                (state, action: PayloadAction<{user: IUser}>) => {
                    state.users = [action.payload.user, ...current(state.users)]
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type === createUser.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // update
            .addMatcher(
                (action) => action.type === updateUser.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === updateUser.fulfilled.type,
                (state, action: PayloadAction<{user: IUser}>) => {
                    state.users = current(state).users.map(user => user.id === action.payload.user.id ? action.payload.user : user)
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type === updateUser.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // staff
            .addMatcher(
                (action) => action.type === getStaff.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === getStaff.fulfilled.type,
                (state, action: PayloadAction<{staff: IUser[]}>) => {
                    state.staff = [...action.payload.staff]
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type === getStaff.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // add server
            .addMatcher(
                (action) => action.type === addUserServer.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === addUserServer.fulfilled.type,
                (state, action: PayloadAction<{user: IUser}>) => {
                    if(state.user) {
                        state.user = action.payload.user
                        state.users = current(state).users.map(user => user.id === action.payload.user.id ? action.payload.user : user)
                    }
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type === addUserServer.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // remove server
            .addMatcher(
                (action) => action.type === removeUserServer.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === removeUserServer.fulfilled.type,
                (state, action: PayloadAction<{user: IUser}>) => {
                    if(state.user) {
                        state.user = action.payload.user
                        state.users = current(state).users.map(user => user.id === action.payload.user.id ? action.payload.user : user)
                    }
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type === removeUserServer.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // get manager users
            .addMatcher(
                (action) => action.type === getManagerUsers.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === getManagerUsers.fulfilled.type,
                (state, action: PayloadAction<{users: IUser[]}>) => {
                    state.manager_users = action.payload.users
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type === getManagerUsers.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // delete users
            .addMatcher(
                (action) => action.type === deleteUser.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === deleteUser.fulfilled.type,
                (state, action: PayloadAction<{id: string}>) => {
                    state.users = current(state.users).filter(user => user.id !== action.payload.id)
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type === deleteUser.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
    },
});

export const { clearUser, clearUsers, clearStaff } = userSlice.actions;

export default userSlice.reducer;
