import { useCallback, useEffect, useRef, useState } from "react";
import { Typography, Box, Button, Grid} from "@mui/material"
import { useDispatch, useSelector } from "../../../redux/store";
import { useParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { Colors } from "../../../types";
import { useTheme } from "../../../services/theme";
import { ModalLoadContacts, ModalSendCampaign } from "./utils";
import { getTemplates } from "../../../redux/slices/templates";
import useDebounce from "../../../services/hooks";
import Image from "image-js";
import { Buffer } from 'buffer';
import { useAuth } from "../../../services/authContext";
import { fabric } from 'fabric';
import { CampaignStaff } from "./components/staff";
import { CampaignSchedule } from "./components/schedule";
import { CampaignContacts } from "./components/contacts";
import { CampaignTemplates } from "./components/templates";
import {toast} from "react-toastify";
import {Details} from "./components/details";
import {
    getCampaign,
    getListDetailsCampaign, pauseCampaign,
    recoverListContacts,
    removeListContacts, updateCampaign, updateCampaignTemplate, updateCampaignTemplates
} from "../../../redux/campaign/campaign.request";
import {clearCampaign} from "../../../redux/campaign/campaign.slice";


export const CampaignsForm = () => {
    const { me } = useAuth()
    const [isSendCampaignModal, setSetCampaignModal] = useState(false)
    const [message, setMessage] = useState('')
    const [filterMaster, setFilterMaster] = useState(false)
    const [filterChannels, setFilterChannels] = useState(false)
    const [file, setFile] = useState<any>();
    const debouncedMessage = useDebounce(message, 500);
    const { campaign, listDetails, isLoadingRecoverList, isLoadingRemoveList } = useSelector(store => store.campaign)
    const [selectedTemplates, setSelectedTemplates] = useState<any[]>([]);
    const [selectedTemplate, setSelectedTemplate] = useState<any | null>(null)
    const [selectedLists, setSelectedLists] = useState<any | null>([])
    const [attachment, setAttachment] = useState<string | null>(null)
    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [sizeMBFile, setSizeMBFile] = useState<any>(null);
    const attachmentInputRef = useRef<any>(null);
    const dispatch = useDispatch()
    const { id } = useParams()
    const theme = useTheme()


    useEffect(() => {
        const f = async () => {
            if(id) {
                const resCampaign = await dispatch(getCampaign(id))
                setSelectedTemplates(resCampaign.payload.campaign.templates.map((it: any) => it.id))
                setAttachment(resCampaign.payload.campaign.attachment)

                dispatch(getListDetailsCampaign({id}));

            }
            dispatch(getTemplates())
        }
        f()

        return () => { dispatch(clearCampaign()) }
    }, [id])
    
    useEffect(() => {
        if(campaign?.message) setMessage(campaign?.message)
    }, [campaign?.message])

    const removeList = async ( dip_carrier: string ) => {
        await dispatch(removeListContacts( { id, payload: {dip_carrier} } ));
        await dispatch(getListDetailsCampaign({id}));
        await dispatch(updateCampaign({ id, payload: { attachment: null } }))
    }


    const recoverList = async ( dip_carrier: string ) => {
        await dispatch(recoverListContacts( { id, payload: {dip_carrier} } ));
        await dispatch(getListDetailsCampaign({id}));
        await dispatch(updateCampaign({ id, payload: { attachment: null } }))
    }

    // useEffect(() => { 
    //     const f = async () => {
    //         if(campaign) {
    //             setAttachment(campaign.attachment)
    //         }
    //     }
    //     f()
    // }, [campaign])

    // useEffect(() => {
    //     if(message && selectedTemplate) {
    //         dispatch(updateCampaignTemplate({ campaign_id: id, payload: { template_id: selectedTemplate.id, message } }))
    //     } else {
    //         dispatch(updateCampaign({ id, payload: { message } }))
    //     }
        
    // }, [debouncedMessage])

    const handleSaveMessage = useCallback(() => {
        if(message && selectedTemplate) {
            setMessage('')
            dispatch(updateCampaignTemplate({ campaign_id: id, payload: { template_id: selectedTemplate.id, message } }))
        } else {
            dispatch(updateCampaign({ id, payload: { message } }))
        }
    }, [message, selectedTemplate])

    const resetImages = () => {
        if (attachmentInputRef.current) {
            attachmentInputRef.current.value = "";
        }
        setSizeMBFile(null);
        setSelectedFile(null);
        setAttachment(null);

        toast('The file could not be added. Maximum allowed image dimensions are 3072x3072 pixels, and the file size must not exceed 500KB', { type: 'error' })
    }

    const onChangeAttachment = (event: any) => {
        const file = event.target.files[0];
        const sizeMB = Number((file.size / (1024 * 1024)).toFixed(2));

        setSizeMBFile(sizeMB);

        if (sizeMB > 0.5) {
            resetImages();
            return;
        }

        setSelectedFile(file);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            const img = new window.Image();
            img.src = reader.result as string;

            img.onload = () => {
                const width = img.width;
                const height = img.height;

                if(width > 3072 || height > 3072) {
                    resetImages();
                    return;
                }

                addNoiseToImage(reader.result);
            }

        };

    }

    const addNoiseToImage = async (src: any) => {
        const imgElement = document.createElement('img');
        imgElement.src = src;
        imgElement.onload = async () => {
          const canvas = new fabric.Canvas('canvas');
          canvas.setWidth(imgElement.width);
          canvas.setHeight(imgElement.height);
    
          const imgInstance = new fabric.Image(imgElement, {
            width: imgElement.width,
          });
    
          canvas.add(imgInstance);
    
          imgInstance.filters?.push(new fabric.Image.filters.Noise({
            noise: 10
          }));
    
          imgInstance.applyFilters();
          canvas.renderAll();
    
          const noisyImageUrl = canvas.toDataURL();

          const blob = await fetch(noisyImageUrl).then(r => r.blob());
          const buffer = await blob.arrayBuffer();
  
          const image = await Image.load(buffer);
  
          const resizedImage = image.resize({ width: 1000, height: 1000 });
  
          const imgBuffer = resizedImage.toBuffer();
          
          const base64 = Buffer.from(imgBuffer)
              .toString('base64');

              setAttachment(base64);
              setSelectedFile(file);
        };  
    };

    const getBase64 = (file: any) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
    };

    const handlePause = () => {
        dispatch(pauseCampaign({ id }))
    }

    // useEffect(() => { 
    //     console.log(campaign?.templates)
    //     if(selectedTemplates && campaign) setSelectedTemplates(campaign?.templates.map((it: any) => it.template_id) || []) 
    // }, [])

    // const handleSave = async () => {
       
    // }
    useEffect(() => {
        if(attachment) dispatch(updateCampaign({ id, payload: { attachment: attachment } }))
    }, [attachment])

    const handleRemoveAttachment = () => {
        setAttachment(null)
        if (attachmentInputRef.current) {
            attachmentInputRef.current.value = "";
            attachmentInputRef.current.type = "text";
            attachmentInputRef.current.type = "file";
        }
        dispatch(updateCampaign({ id, payload: { attachment: null } }))
    }

    const handleUpdate = (id: string, payload: any): void => {
        // for save with button, save data to update array and after each upd check changes and replace if id is equale
        // example: updArr.map(it => it.id === id ? { id, payload: { ...it.payload, ...payload } } : it)
        dispatch(updateCampaign({ id, payload }))
    }
    
    useEffect(() => {
        if(campaign && campaign.templates.length !== selectedTemplates.length) dispatch(updateCampaignTemplates({ campaign_id: campaign?.id, payload: { template_ids: selectedTemplates }}))
    }, [selectedTemplates])

    if(!campaign) return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 80px)' }}><CircularProgress></CircularProgress></Box>

    const SendingButtons = () => {
        if(campaign.status === 'created') return <>{ me?.role !== 'admin' && (<Button onClick={() => setSetCampaignModal(true)} size="large" variant="contained">SEND</Button>)}</>
        if(campaign.status === 'pending') return <>{ <Button onClick={handlePause} variant="contained" style={{ display: 'flex', alignItems: 'center' }}>Pause</Button>}</>
        // if(campaign.status === 'pending') return <>{ <Typography style={{ display: 'flex', alignItems: 'center' }}>PENDING...</Typography>}</>
        if(campaign.status === 'sended') return <>{ <Typography style={{ display: 'flex', alignItems: 'center' }}>SENDED</Typography>}</>
        
        return (
            <>
               <>{ me?.role !== 'admin' && (<Button onClick={() => setSetCampaignModal(true)} size="large" variant="contained">SEND</Button>)}</>
            </>
        )
    }

    return (

        <div>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h5">Campaigns</Typography><span> /{campaign?.name}</span>
            </Box>
            
            <Grid style={{ marginTop: 10 }} container spacing={2}>

                <Grid item xs={12} sm={10} md={10}>
                    <Box 
                        sx={{ 
                            backgroundColor: theme === 'dark' ? Colors.MAIN_DARK : Colors.MAIN_WHITE,
                            padding: 4,
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: 2,
                        }}
                    >
                        <Box>
                            <Typography variant="subtitle1">{campaign.name} - {campaign.type === 'single_blast' ? 'SINGLE_BLAST' : 'SCHEDULE'}</Typography>
                            <Typography variant="caption">Here you can upload your list, select options for list, set text message, and permissions</Typography>
                        </Box>
                        <SendingButtons></SendingButtons>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={2} md={2}>
                    <Box 
                        sx={{ 
                            backgroundColor: theme === 'dark' ? Colors.MAIN_DARK : Colors.MAIN_WHITE, 
                            height: '100%',
                            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                        }}
                    >
                        <Typography style={{ padding: 8, display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', alignItems: 'center', height: '100%' }} variant="subtitle2">TOTAL CONTACTS <span>{campaign.total_contacts || 0}</span></Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                    <CampaignContacts 
                        filterChannels={filterChannels} filterMaster={filterMaster} selectedLists={selectedLists} setSelectedLists={setSelectedLists}
                        setFile={setFile} setFilterChannels={setFilterChannels} setFilterMaster={setFilterMaster}
                    ></CampaignContacts>

                    {campaign.type === 'scheduled' && <CampaignSchedule campaign={campaign}></CampaignSchedule>}

                    <Details listDetails={listDetails} removeList={removeList} recoverList={recoverList} ></Details>
                </Grid>
                
                <Grid item xs={12} sm={6} md={6}>
                    <Box 
                        sx={{ 
                            backgroundColor: theme === 'dark' ? Colors.MAIN_DARK : Colors.MAIN_WHITE,
                            padding: 2,
                        }}
                    >
                        <Typography variant="subtitle1">INCLUDE IMAGE</Typography>
                        <input 
                            ref={attachmentInputRef}
                            style={{ marginTop: 10, cursor: 'pointer' }} 
                            type="file" 
                            onChange={onChangeAttachment}
                        ></input>
                        {/* {previewUrl && <img style={{ marginTop: 10 }} height={300} width={'100%'} src={previewUrl} alt="Preview" />} */}
                        {attachment && <img height={400}
                            src={attachment.includes('base64') ? `${attachment}` : `data:image/jpeg;base64,${attachment}`} 
                            alt="With Noise"
                        />}
                        <Button style={{ marginTop: 10 }} onClick={handleRemoveAttachment}>Remove</Button>
                        {/* <Button style={{ marginTop: 10 }} onClick={handleSave}>Save</Button> */}
                    </Box>
                    
                    <CampaignTemplates 
                        handleSaveMessage={handleSaveMessage}
                        message={message} setMessage={setMessage} campaign={campaign}
                        selectedTemplates={selectedTemplates} setSelectedTemplates={setSelectedTemplates}
                        selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate}
                    ></CampaignTemplates>

                    <CampaignStaff campaign={campaign}></CampaignStaff>

                </Grid>


            </Grid>

            <ModalSendCampaign 
                open={isSendCampaignModal} 
                close={() => setSetCampaignModal(false)}
                campaign_id={campaign.id}
                templatesLength={campaign.templates.length}
                message={message}
            ></ModalSendCampaign>
            <ModalLoadContacts filter_master={filterMaster} filter_channels={filterChannels} lists={selectedLists} campaign_id={campaign.id} file={file} close={() => setFile(null)}></ModalLoadContacts>
        </div>
    )
}
