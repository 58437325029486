import React, {useEffect, useState} from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, TextField, Box, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Button, Typography, Tooltip, IconButton
} from '@mui/material';

import {useDispatch, useSelector} from "../../../../redux/store";
import {
    aiDevices,
    getDev,
    getDevList,
    restartDevice,
    restartDevices, startDevices,
    stopDevice, stopDevices,
    updateDevice
} from "../../../../redux/sim/sim.request";
import {IDeviceOneData, IUpdateDevicesData} from '../../../../redux/sim/sim.interface';
import {socket} from "../../../../services/socket";
import {onNewSms} from "../../../../redux/slices/chat";


const StatusIndicator = ({ status }: { status: string }) => {
    const getColor = () => {
        switch (status) {
            case 'green':
                return 'green';
            case 'yellow':
                return 'yellow';
            case 'red':
                return 'red';
            default:
                return 'gray';
        }
    };

    return (
        <Box
            sx={{
                width: 16,
                height: 16,
                borderRadius: '50%',
                backgroundColor: getColor(),
            }}
        />
    );
};

export const SettingsSim = () => {

    const [analysis, setAnalysis] = useState(false);
    const [selectedDevice, setSelectedDevice] = useState('');
    const [rows, setRows] = useState<IDeviceOneData[]>([]);
    const { devices, deviceOne } = useSelector(store => store.sim)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getDevList());

        socket.on('control_device', (data): any => {
            console.log('control_device', data);
            if(data.status === 'update')
            {
                dispatch(getDevList());
            }
        })
        return () => { socket.off('control_device')}

    }, [])

    useEffect(() => {
        showList()
    }, [devices]);

    useEffect(() => {
        if (Array.isArray(deviceOne)) {
            setRows(deviceOne);
        } else {
            setRows([]);
        }
    }, [deviceOne]);

    const showList = () => {
        const firstItem = Object.keys(devices)[0]
        setSelectedDevice(firstItem)
        if(!!selectedDevice)
            dispatch(getDev(selectedDevice))
    }

    const handleInputChange = (
        index: number,
        field: number,
        value: any,
        { dev, port }: { dev: string; port: string }
    ) => {
        console.log('handleInputChange')
        const updatedRows = [...rows];

        const updatedRow = { ...updatedRows[index] };

        const updatedParams: any = Array.isArray(updatedRow.params) ? [...updatedRow.params] : [];

        const updatedControl: any = updatedRow.control ? { ...updatedRow.control } : {};

        updatedParams[field] = value;

        updatedRow.params = updatedParams;
        updatedRow.control = updatedControl;

        updatedRows[index] = updatedRow;

        setRows(updatedRows);

        let data: IUpdateDevicesData = {};
        if (field === 0) data.interval = Number(value);
        if (field === 1) data.maxHour = Number(value);
        if (field === 2) data.maxDay = Number(value);
        if (field === 3) data.intervalDay = value;
        if (field === 4) data.blockDay = Number(value);

        dispatch(updateDevice({ dev, port, payload: data }));
    };

    const handleDeviceChange = (event: SelectChangeEvent<string>) => {
        setSelectedDevice(event.target.value as string);
        dispatch(getDev(selectedDevice))
    };

    const correctStatus = (object: {
        stop: boolean,
        restart: boolean,
        analysis: boolean
    }) => {
        if(object.stop)
            return 'red'
        else if(object.analysis)
            return 'yellow'
        else
            return 'green'
    }

    const deviceRestart = (dev: string, port: string) => {
        dispatch(restartDevice({dev, port}))
    }

    const deviceStop = (dev: string, port: string) => {
        dispatch(stopDevice({dev, port}))
    }

    const devicesStop = () => {
        dispatch(stopDevices({dev: selectedDevice}))
    }

    const devicesStart = () => {
        dispatch(startDevices({dev: selectedDevice}))
    }

    const devicesRestart = () => {
        dispatch(restartDevices({dev: selectedDevice}))
    }

    const toggleAI = (dev: string, port: string, analysis: boolean) => {

        dispatch(aiDevices({ dev, payload: {por: port, analysis: !analysis} }));
        setAnalysis(!analysis);

    };

    return(
        <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>

                <Box sx={{ display: 'flex', marginRight: 2, alignItems: 'center' }}>
                    <FormControl variant="outlined" sx={{ minWidth: 200 }}>
                        <InputLabel id="device-select-label">Select Device</InputLabel>
                        <Select
                            labelId="device-select-label"
                            value={selectedDevice}
                            onChange={handleDeviceChange}
                            label="Select Device"
                        >
                            {Object.keys(devices).map((device, index) => (
                                <MenuItem key={index} value={device}>
                                    {device}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                <Box sx={{display: 'flex', textAlign: 'center', alignItems: 'center'}}>

                    <Typography variant="subtitle1" sx={{mr: 2}}>
                        Start/Restart All Ports:
                    </Typography>

                    <Tooltip title="Restart all port in devices with reset settings" arrow>
                        <Button
                            variant="contained" sx={{ mr: 2 }}
                            onClick={devicesRestart}
                            disabled={!selectedDevice}
                        >
                            Restart
                        </Button>
                    </Tooltip>

                    <Tooltip title="Refresh the list and reset all data, starting from a clean slate" arrow>
                        <Button
                            variant="contained" sx={{ mr: 2 }}
                            onClick={devicesStart}
                            disabled={!selectedDevice}
                        >
                            Start
                        </Button>
                    </Tooltip>

                    <Tooltip title="Stopping the algorithm for testing all ports" arrow>
                        <Button
                            variant="contained"
                            sx={{ backgroundColor: 'red', '&:hover': { backgroundColor: 'darkred' } }}
                            onClick={devicesStop}
                            disabled={!selectedDevice}
                        >
                            Stop
                        </Button>
                    </Tooltip>

                    <Tooltip title="Toggle all AI" arrow>
                        <Button
                            variant="contained"
                            sx={{
                                ml: 2,
                                backgroundColor: 'white',
                                border: '2px solid #2196f3',
                                color: '#2196f3',
                                '&:hover': {
                                    backgroundColor: 'white'
                                }
                            }}
                            onClick={() => toggleAI(selectedDevice, 'all', analysis)}
                        >
                            AI
                            <Box
                                sx={{
                                    width: 16,
                                    height: 16,
                                    borderRadius: '50%',
                                    backgroundColor: analysis ? 'green' : 'red',
                                    ml: 1,
                                }}
                            />
                        </Button>
                    </Tooltip>

                </Box>
            </Box>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="editable table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Port</TableCell>
                            <TableCell>Actions</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Interval (s)</TableCell>
                            <TableCell>Max Msg/Day</TableCell>
                            <TableCell>Max Msg/Hour</TableCell>
                            <TableCell>Send Interval (Days)</TableCell>
                            <TableCell>Blocked Days</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(rows) && rows.length > 0 ? (
                            rows.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        {row.port}
                                    </TableCell>
                                    <TableCell>

                                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                                            <Tooltip title="Restart port in device with reset settings" arrow>
                                                <Button
                                                    variant="contained" sx={{ mr: 2 }}
                                                    onClick={() => deviceRestart(row.server_name, row.port)}
                                                >
                                                    Restart
                                                </Button>
                                            </Tooltip>

                                            <Tooltip title="Stopping the port testing algorithm">
                                                <Button
                                                    variant="contained"
                                                    sx={{ backgroundColor: 'red', '&:hover': { backgroundColor: 'darkred' } }}
                                                    onClick={() => deviceStop(row.server_name, row.port)}
                                                >
                                                    Stop
                                                </Button>
                                            </Tooltip>

                                            <Tooltip title="Toggle AI" arrow>
                                                <Button
                                                    variant="contained"
                                                    sx={{
                                                        ml: 2,
                                                        backgroundColor: 'white',
                                                        border: '2px solid #2196f3',
                                                        color: '#2196f3',
                                                        '&:hover': {
                                                            backgroundColor: 'white'
                                                        }
                                                    }}
                                                    onClick={() => toggleAI(row.server_name, row.port, row.control.analysis)}
                                                >
                                                    AI
                                                    <Box
                                                        sx={{
                                                            width: 16,
                                                            height: 16,
                                                            borderRadius: '50%',
                                                            backgroundColor: row.control.analysis ? 'green' : 'red',
                                                            ml: 1,
                                                        }}
                                                    />
                                                </Button>
                                            </Tooltip>

                                        </Box>

                                    </TableCell>
                                    <TableCell>
                                        <StatusIndicator status={correctStatus(row.control)} />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="number"
                                            value={row.params[0]}
                                            onChange={(e) => handleInputChange(index, 0, parseInt(e.target.value), {dev: row.server_name, port: row.port})}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="number"
                                            value={row.params[2]}
                                            onChange={(e) => handleInputChange(index, 2, parseInt(e.target.value), {dev: row.server_name, port: row.port})}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="number"
                                            value={row.params[1]}
                                            onChange={(e) => handleInputChange(index, 1, parseInt(e.target.value), {dev: row.server_name, port: row.port})}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="text"
                                            value={Array.isArray(row.params[3]) ? row.params[3].join(',') : '0,0'}
                                            onChange={(e) => handleInputChange(index, 3, e.target.value.split(',').map(Number), {dev: row.server_name, port: row.port})}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="number"
                                            value={row.params[4]}
                                            onChange={(e) => handleInputChange(index, 4, parseInt(e.target.value), {dev: row.server_name, port: row.port})}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={8} align="center">
                                    No data available
                                </TableCell>
                            </TableRow>
                        )}


                    </TableBody>
                </Table>
            </TableContainer>
        </Box>

    );
}