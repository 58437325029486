import { createSlice, createAsyncThunk, PayloadAction, current } from "@reduxjs/toolkit";
import { Request } from "../request";
import { toast } from "react-toastify";

export interface IList {
  id: string;
  name: string
  createdAt: string;
}

type State = {
  list: IList | null;
  lists: IList[];
  isLoading: boolean;
};

const initialState: State = {
  list: null,
  lists: [],
  isLoading: false,
};


export const getList = createAsyncThunk(
  "/lists/get",
  async ({}: any, { rejectWithValue }) => {
    try {
        const response = await Request.get(`lists/${'id'}`)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getLists = createAsyncThunk(
  "/lists/getAll",
  async (_, { getState, rejectWithValue }: any) => {
    try {
        const response = await Request.get(`lists`)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const createList = createAsyncThunk(
  "/lists/create",
  async (payload: any, { rejectWithValue }) => {
    try {
        const response = await Request.post('lists', payload)
        toast('created', { type: 'success' })
        return response
    } catch (error: any) {
        toast('some error', { type: 'error' })
      return rejectWithValue(error);
    }
  }
);

export const updateList = createAsyncThunk(
  "/lists/update",
  async ({ id, payload }: any, { rejectWithValue }) => {
    try {
        const response = await Request.post(`lists/${id}`, payload)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const deleteList = createAsyncThunk(
  "/lists/delete",
  async (id: string, { rejectWithValue }) => {
    try {
        const response = await Request.delete(`lists/${id}`)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);


const listSlice = createSlice({
  name: "list",
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder
      // get
      .addMatcher(
        (action) => action.type === getList.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === getList.fulfilled.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === getList.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
      // get all
      .addMatcher(
        (action) => action.type === getLists.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === getLists.fulfilled.type,
        (state, action: PayloadAction<{lists: IList[]}>) => {
            state.lists = [...action.payload.lists]
            state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === getLists.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
      // create
      .addMatcher(
        (action) => action.type === createList.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === createList.fulfilled.type,
        (state, action: PayloadAction<{list: IList}>) => {
            state.lists = [...current(state.lists), action.payload.list]
            state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === createList.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
      // update
      .addMatcher(
        (action) => action.type === updateList.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === updateList.fulfilled.type,
        (state, action: PayloadAction<{list: IList}>) => {
            state.lists = current(state).lists.map(list => list.id === action.payload.list.id ? action.payload.list : list)
            state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === updateList.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
      // delete
      .addMatcher(
        (action) => action.type === deleteList.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === deleteList.fulfilled.type,
        (state, action: PayloadAction<{id: string}>) => {
            state.lists = current(state).lists.filter(list => list.id !== action.payload.id)
            state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === deleteList.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
  },
});

export const { } = listSlice.actions;

export default listSlice.reducer;
