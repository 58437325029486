import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import { ThemeSwitcher, useTheme } from "../../services/theme";
import { Colors } from "../../types";
import { useEffect, useState } from "react";
import '../../App.scss';
import { useAuth } from "../../services/authContext";
import { useNavigate, useLocation } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { SignIn, isUserLogged } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        document.body.className = 'body_overflow_hidden';
        return () => { document.body.className = document.body.className.replace("body_overflow_hidden", ""); };
    }, []);

    useEffect(() => {
        if (isUserLogged) {
            const lastVisitedPath = localStorage.getItem('lastVisitedPath') || '/admin';
            navigate(lastVisitedPath, { replace: true });
            localStorage.removeItem('lastVisitedPath');
        }
    }, [isUserLogged, navigate]);

    const onSubmit = () => {
        SignIn({ email, password });
    };

    return (
        <Box sx={{
            height: '100vh',
            backgroundColor: theme === 'dark' ? Colors.SECONDARY_DARK : Colors.SECONDARY_LIGTH,
            color: theme === 'dark' ? Colors.MAIN_WHITE : Colors.MAIN_BLACK,
            display: 'flex', flexDirection: 'column', alignItems: 'center'
        }}>
            <Box sx={{ alignSelf: 'flex-end', marginRight: 10 }}>
                <ThemeSwitcher />
            </Box>
            <Box sx={{
                display: 'flex', justifyContent: 'center', flexDirection: 'column',
                marginTop: 10, width: '50%', minWidth: '300px'
            }}>
                <Typography style={{ textAlign: 'center' }} variant="h5">SignIn</Typography>
                <TextField
                    type='email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{ marginTop: 20 }}
                    placeholder="Email"
                    fullWidth
                    name="email"
                    autoComplete="email"
                />
                <TextField
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    style={{ marginTop: 20 }}
                    placeholder="Password"
                    fullWidth
                    name="password"
                    autoComplete="current-password"
                    InputProps={{
                        endAdornment: (
                            <IconButton onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        ),
                    }}
                />
                <Button onClick={onSubmit} size="large" style={{ marginTop: 20 }} variant="contained">Sign In</Button>
            </Box>
        </Box>
    );
};
