import { Box, Button, TextField, Typography } from "@mui/material"
import { ThemeSwitcher, useTheme } from "../../services/theme"
import { Colors } from "../../types"
import { useEffect } from "react"
import '../../App.scss'
import { useNavigate } from "react-router-dom"

export const NotFound = () => {
    const theme = useTheme()
    const navigate = useNavigate()

    useEffect(() => {
        document.body.className = 'body_overflow_hidden'
        return () => { document.body.className = document.body.className.replace("body_overflow_hidden","") }
    }, [])

    return (
    <Box sx={{ height: '100vh', 
        backgroundColor: theme === 'dark' ? Colors.SECONDARY_DARK : Colors.SECONDARY_LIGTH,
        color: theme === 'dark' ? Colors.MAIN_WHITE : Colors.MAIN_BLACK,
        display: 'flex', flexDirection: 'column', alignItems: 'center'
    }}>
        <Box sx={{ alignSelf: 'flex-end', marginRight: 10 }}><ThemeSwitcher></ThemeSwitcher></Box>
        <Box sx={{ 
            display: 'flex', justifyContent: 'center', flexDirection: 'column', 
            marginTop: 10, width: '50%', minWidth: '300px'
        }}>
            <Typography style={{ textAlign: 'center' }} variant="h4">Page Not Found</Typography>
            <Button onClick={() => navigate('/admin')} style={{ marginTop: 30 }} variant="contained">Go To Homepage</Button>
        </Box>
    </Box>)
}
