import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { amber, deepOrange, grey, blue } from '@mui/material/colors';
import Box from '@mui/material/Box';
import { useTheme as useThemeMui, ThemeProvider as MUIThemeProvider, createTheme } from '@mui/material/styles';
import { Colors } from '../types';

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

const getDesignTokens = (mode: any) => ({
    palette: {
      mode,
      primary: {
        ...blue,
      },
      // background: {
      //     paper: mode === 'dark' ? '#444' : '#999'
      // }
    },
  });

export function ThemeSwitcher() {
  const theme = useThemeMui();
  const colorMode = React.useContext(ColorModeContext);
  
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 1,
        p: 3,
      }}
    >
      <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
        {theme.palette.mode === 'dark' ?  <FontAwesomeIcon
            icon={"fa-solid fa-circle-half-stroke".split(" ") as any}
          ></FontAwesomeIcon> : <FontAwesomeIcon
          icon={"fa-solid fa-circle-half-stroke".split(" ") as any}
        ></FontAwesomeIcon> }
      </IconButton>
      {theme.palette.mode}
    </Box>
  );
}

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
  const default_theme = () => {
    const theme = localStorage.getItem('theme')
    if (theme === 'dark' || theme === 'light') return theme

    return 'light'
  }
  const [mode, setMode] = React.useState<'light' | 'dark'>(default_theme());
  
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          if( prevMode === 'light' ) {
            localStorage.setItem('theme', 'dark')
            return 'dark' 
          } else {
            localStorage.setItem('theme', 'light')
            return 'light' 
          }
        });

      },
    }),
    [],
  );

  const theme = React.useMemo(
    () =>
      createTheme(getDesignTokens(mode)),
    [mode],
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <MUIThemeProvider theme={theme}>
        {children}
      </MUIThemeProvider>
    </ColorModeContext.Provider>
  );
}

export function useTheme() {
    const theme = useThemeMui()
    return theme.palette.mode
}