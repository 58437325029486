import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch as useReduxDispatch, useSelector as useReduxSelector } from "react-redux";
import campaign from "./campaign/campaign.slice";
import user from "./user/user.slice";
import templates from "./slices/templates";
import keywords from "./slices/keywords";
import contacts from "./slices/contacts";
import chat from "./slices/chat";
import sms from "./slices/sms";
import blocked from "./slices/blocked";
import server from "./slices/server";
import groups from "./slices/groups";
import responses from "./slices/responses";
import list from "./slices/list";
import sim from "./sim/sim.slice";

export const store = configureStore({
    reducer: {
      user,
      campaign,
      templates,
      keywords,
      contacts,
      chat,
      sms,
      blocked,
      server,
      groups,
      responses,
      list,
      sim
    },
  });

export type RootStateType = ReturnType<typeof store.getState>;
export type AppDispatchType = typeof store.dispatch;
export const useAppDispatch = () => useReduxDispatch<AppDispatchType>();

export const useSelector: TypedUseSelectorHook<RootStateType> = useReduxSelector
export const useDispatch = useReduxDispatch<AppDispatchType>
