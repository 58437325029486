
export enum UserRoles {
    ADMIN = 'Admin',
    MANAGER = 'Manager',
    STAFF = 'Staff'
}

export enum Colors {
    MAIN_DARK = '#444',
    MAIN_LIGTH = '#999',
    SECONDARY_DARK = '#333',
    SECONDARY_LIGTH = '#dde7eb',
    MAIN_BLACK = '#111',
    MAIN_WHITE = '#fff',
}

export enum KeyWords {
    FirstName = "first_name",
    LastName = "last_name",
    Number = "number",
    // Email = "Email",
    // Company = "Company",
}

export enum KeyWordsTypes {
    equal_to = "Equal to",
    not_equal_to = "Not equal to",
    blank = "Blank",
    not_blank = "Not blank",
    begins_with = "Begins with",
    contains = "Contains",
    does_not_contain = "Does not contain",
}

export enum KeyWordsActions {
    add_to_trash = "Add to trash",
    add_to_dnc = "Add to DNC list",
    add_to_positive = "Add to positive list",
    add_to_stop = "Add to stop list",
}
