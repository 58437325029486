import {
    Box,
    Button,
    Typography
} from "@mui/material";
import { Outlet, useNavigate} from "react-router-dom";


export const Sim = () => {

    const navigate = useNavigate();



    return (
        <>

            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Typography variant="h5">Workspace</Typography><span> /SIM-card</span>
            </Box>

            <Box sx={{
                display: 'flex',
                marginTop: 4,
                marginBottom: 4,
                '& > *:not(:last-child)': {
                    marginRight: 2
                }
            }}>
                <Button
                    variant="contained"
                    onClick={() => navigate('/admin/workspace/sim')}
                >
                    Dashboard
                </Button>
                <Button
                    variant="contained"
                    onClick={() => navigate('/admin/workspace/sim/reports')}
                >
                    Reports
                </Button>
                <Button
                    variant="contained"
                    onClick={() => navigate('/admin/workspace/sim/settings')}
                >
                    Setting
                </Button>
            </Box>

            <Box>
                <Outlet></Outlet>
            </Box>

        </>
    );

}