import { useEffect, useMemo, useRef, useState } from "react";
import * as XLSX from "xlsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography, Box, TableContainer, Table, TableHead, TableRow, TableCell, 
    TableBody, Paper, Container, Button, Stack, Modal, Input, InputLabel, TextField, Divider, Select, FormControl, MenuItem, ButtonGroup, OutlinedInput, SelectChangeEvent, Grid, Chip } from "@mui/material"
import { useDispatch, useSelector } from "../../../redux/store";
import { autocompleteContacts, clearAutocompleteContacts, clearContacts, getContacts, importContact, loadMoreContacts } from "../../../redux/slices/contacts";
import useDebounce from "../../../services/hooks";
import { Request } from "../../../redux/request";
import ExcelJS from 'exceljs'
import { getLists } from "../../../redux/slices/list";
import { Colors } from "../../../types";
import { toast } from "react-toastify";
import { MultiSelectContactsLists } from "../Campaigns/components/contacts";
import {useAuth} from "../../../services/authContext";

export const Contacts = () => {
    const { contacts, autocomplete } = useSelector(store => store.contacts)
    const [openModal, setOpenModal] = useState(false);
    const { me } = useAuth()
    const [text, setText] = useState('')
    const debouncedText = useDebounce(text, 500);
    const [listId, setListId] = useState('')
    const { lists } = useSelector(store => store.list)
    const [horizontalScroll, setHorizontalScroll] = useState(0)
    const dispatch = useDispatch()
    const hiddenInput = useRef<any>(null);
    const [file, setFile] = useState<any>(null)

    const handleClickUpload = (event: any) => {
        hiddenInput.current.click();
    };

   useEffect(() => {
        dispatch(getContacts({ list_id: listId }))
        dispatch(getLists())
        return () => { dispatch(clearContacts()) }
   }, [])

   useEffect(() => {
        if (text) dispatch(autocompleteContacts(text));

        return () => {
        dispatch(clearAutocompleteContacts());
        };
    }, [debouncedText]);

    useEffect(() => {
        dispatch(getContacts({ list_id: listId }))
    }, [listId])

    const contacts_list = useMemo(() => autocomplete || contacts, [autocomplete, contacts])
      
    function handleScroll(event: any) {
        const { scrollTop, scrollHeight, clientHeight, scrollLeft } = event.target;
        const isAtBottom = scrollTop + clientHeight >= scrollHeight - 0.5;

        if(scrollLeft !== horizontalScroll) {
            setHorizontalScroll(scrollLeft)
            return
        }
    
        if (isAtBottom) {
            dispatch(loadMoreContacts({ list_id: listId, text: text}))
        }
    }

    const downloadData = async () => {
        const res = await Request.get(`contacts/download?list=${listId}&text=${text}`)

        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet("My Sheet");
    
        sheet.columns = [
          { header: "name", key: "name", width: 25 },
          { header: "number", key: "number", width: 15 },
          { header: "notes", key: "notes", width: 30 },
          { header: "state", key: "state", width: 40 },
          { header: "email", key: "email", width: 40 },
          { header: "company", key: "company", width: 40 },
          { header: "lists", key: "lists", width: 40 }
        ];
        res.contacts?.map((it: any) => {
            let fields: any = {};
            it.fields.forEach((field: any) => {
                fields[field.key] = field.value;
            });
            fields['lists'] = '';
            it.lists?.forEach((l: any, index: number) => {
                fields['lists'] += `${l.name}${index < it.lists.length - 1 ? ', ' : ''}`;
            });
        
            sheet.addRow({
                name: `${it.first_name || ''} ${it.last_name || ''}`,
                number: it.number,
                notes: it.notes,
                ...fields,    
            }).alignment = { wrapText: true };
        });
        

        workbook.xlsx.writeBuffer().then(function (data) {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.download = "download.xlsx";
          anchor.click();
          window.URL.revokeObjectURL(url);
        });
    }

    const downloadDataStop = async () => {
        const res = await Request.get(`contacts/downloadstop/${me?.id}`)

        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet("My Sheet");
    
        sheet.columns = [
          { header: "name", key: "name", width: 25 },
          { header: "number", key: "number", width: 15 },
          { header: "notes", key: "notes", width: 30 },
          { header: "state", key: "state", width: 40 },
          { header: "email", key: "email", width: 40 },
          { header: "company", key: "company", width: 40 },
          { header: "lists", key: "lists", width: 40 }
        ];
        res.contacts?.map((it: any) => {
            let fields: any = {};
            it.fields.forEach((field: any) => {
                fields[field.key] = field.value;
            });
            fields['lists'] = '';
            it.lists?.forEach((l: any, index: number) => {
                fields['lists'] += `${l.name}${index < it.lists.length - 1 ? ', ' : ''}`;
            }); 
            sheet.addRow({
                name: `${it.first_name || ''} ${it.last_name || ''}`,
                number: it.number,
                notes: it.notes,
                ...fields,    
            }).alignment = { wrapText: true };
        });
        

        workbook.xlsx.writeBuffer().then(function (data) {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.download = "download.xlsx";
          anchor.click();
          window.URL.revokeObjectURL(url);
        });
    }




    const handleFileUpload = (e: any) => {
        const reader = new FileReader();
        reader.readAsBinaryString(e.target.files[0]);
        reader.onload = (e: any) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const parsedData: any[] = XLSX.utils.sheet_to_json(sheet, { defval: '' });
          if (hiddenInput.current) {
            hiddenInput.current.value = "";
            hiddenInput.current.type = "text";
            hiddenInput.current.type = "file";
          }

          if(parsedData.length) {
            setFile(parsedData as any)
          }
        };
    };

    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h5">Contacts</Typography>
                <TextField value={text} onChange={(e) => setText(e.target.value)} style={{ width: '50%', height: '100%' }} placeholder="Search"></TextField>
            </Box>

            <Container sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 4, width: '100%' }}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={3}>
                        <Button onClick={downloadData} style={{ width: '100%', height: '100%' }} variant="contained">
                            Download Contacts List
                        </Button>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Button style={{ width: '100%', height: '100%' }} variant="contained" onClick={downloadDataStop}>Download Stops List</Button>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <Button onClick={handleClickUpload} style={{ width: '100%', height: '100%' }} variant="contained">Upload List</Button>
                        <input onChange={handleFileUpload} type="file" style={{ display: 'none' }} ref={hiddenInput}></input>
                    </Grid>
                </Grid>
            </Container>
            
            {/* <TableContainer onScroll={handleScroll} style={{ marginTop: 30, maxHeight: 'calc(100vh - 280px)' }} component={Paper}> */}
            <TableContainer onScroll={handleScroll} style={{ marginTop: 30, maxHeight: 'calc(100vh - 280px)' }} component={Paper}>
                <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }}>
                    <TableHead>
                    <TableRow>
                        <TableCell>NAME</TableCell>
                        <TableCell>NUMBER</TableCell>
                        <TableCell>META DATA</TableCell>
                        <TableCell>CHANNEL</TableCell>
                        <TableCell>
                            <FormControl style={{ marginTop: 10, width: '100%', }}>
                                <InputLabel sx={{ color: Colors.MAIN_LIGTH, }} id="demo-simple-select-label">Select List</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Select List"
                                    onChange={e => setListId(e.target.value as string)}
                                >
                                    <MenuItem value={''}>None</MenuItem>
                                    <MenuItem value={'without'}>Without</MenuItem>
                                    {
                                        lists.map(it => <MenuItem key={it.id} value={it.id}>{it.name}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {contacts_list.map((contact, n) => {
                        return (<TableRow
                            key={contact.number + n + Math.random()}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">{contact.first_name} {contact.last_name}</TableCell>
                            <TableCell>{contact.number}</TableCell>
                            <TableCell>
                                {
                                    contact.fields?.map(field =>
                                        <Box key={field.key + field.contact_number} sx={{ display: 'flex', gap: 2 }}>
                                            <Typography>{field.key}:</Typography>
                                            <Typography>{field.value || '---'}</Typography>
                                        </Box>
                                    )
                                }
                            </TableCell>
                            <TableCell>{ contact.lists?.length > 0 ? contact.lists.map(list => <Typography>{list.name}</Typography>) : <Typography>---</Typography>}</TableCell>
                            <TableCell style={{ minWidth: 100 }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                
                                </Box>
                            </TableCell>
                            <TableCell><Button variant="contained">Stop</Button></TableCell>
                        </TableRow>)
                    })}
                    </TableBody>
                </Table>
            </TableContainer>

            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box 
                    sx={{ 
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '50%',
                        bgcolor: 'background.paper',
                        color: 'text.primary',
                        boxShadow: 24,
                        p: 4, 
                    }}
                >
                    
                </Box>
            </Modal>

            {file && <ModalLoadContacts file={file} close={() => setFile(null)}></ModalLoadContacts>}
        </div>
    )
}

const ModalLoadContacts = ({ file, close }: { file: any, close: any }) => {
    const { lists } = useSelector(store => store.list)
    const [disabledButton, setDisabledButton] = useState(false);
    const [firstKeysWithoutPrep, setFirstKeysWithoutPrep] = useState<string[] | null>(null)
    const [firstKeys, setFirstKeys] = useState<string[] | null>(null)
    const [keys, setKeys] = useState<string[] | null>(null)
    const [listName, setListName] = useState('')
    const [selectedLists, setSelectedLists] = useState<any | null>([])
    const dispatch = useDispatch()
    
    useEffect(() => {
        dispatch(getLists())
    }, [])

    useEffect(() => {
        if(file) {
            setKeys(Object.keys(file[0]).map(it => it.toLocaleLowerCase().replace(' ', '_').replace(/[.]/, '')))
            setFirstKeys(Object.keys(file[0]).map(it => it.toLocaleLowerCase().replace(' ', '_').replace(/[.]/, '')))
            setFirstKeysWithoutPrep(Object.keys(file[0]))
        }
    }, [file])

    const onSubmit = async () => {
        if(!listName) {
            toast('You should write list name')
            return 
        }
        if(!keys?.includes('number')) {
            toast('You should select number field')
            return 
        }
        const duplicatesFound = duplicates(keys);
        if(duplicatesFound.length) {
            console.log("Duplicate elements: " + duplicatesFound.join(" "));
            return
        }

        const preparedFile: any[] = file.map((it: any, n: number) => {
            const obj: any = {}
            if(firstKeysWithoutPrep) keys.map((key, n) => { 
                if(key === 'not_include') return
                obj[key] = it[firstKeysWithoutPrep[n]]
            })
            return obj
        })

        for(let row of preparedFile) {
            if(!row.first_name) row.first_name = row.number
        }

        setDisabledButton(true);

        try {
            const res = await dispatch(importContact({
                payload: { contacts: preparedFile, list_name: listName, lists }
            }));

            if (res.payload) {
                close();
            }
        } catch (error) {
            console.error("Error uploading contacts:", error);
        } finally {
            setDisabledButton(false);
        }
    }

    if(!file?.length) return null


    return (
        <Modal
            open={!!file} 
            onClose={close}
        >
            <Box 
                sx={{ 
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '50%',
                    bgcolor: 'background.paper',
                    color: 'text.primary',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <TextField
                    value={listName}
                    onChange={(e) => setListName(e.target.value)}
                    style={{ marginTop: 30, marginBottom: 30 }} 
                    placeholder="List Name" 
                    fullWidth
                ></TextField>
                <Typography>Select number, first_name and last_name fields</Typography>
                <TableContainer style={{ maxHeight: 'calc(100vh - 300px)' }} component={Paper}>
                <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }}>
                    <TableHead>
                    <TableRow>
                        {
                            keys?.map((it, n) => {

                                return <TableCell key={it + n}>
                                <FormControl style={{ marginTop: 10, width: '100%', }}>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={it}
                                        onChange={(e => {
                                            setKeys(prev => prev?.map((item, j) => n !== j ? item : e.target.value ) || null )} 
                                        )}
                                    >
                                        {firstKeys && <MenuItem value={firstKeys[n]}>{firstKeys[n]}</MenuItem>}
                                        {firstKeys && firstKeys[n] !== 'number' && <MenuItem value={'number'}>number</MenuItem>}
                                        {firstKeys && firstKeys[n] !== 'first_name' && <MenuItem value={'first_name'}>first_name</MenuItem>}
                                        {firstKeys && firstKeys[n] !== 'last_name' && <MenuItem value={'last_name'}>last_name</MenuItem>}
                                        {<MenuItem value={'not_include'}>not_include</MenuItem>}
                                    </Select>
                                </FormControl>
                            </TableCell>
                            })
                        }
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {[...file]?.slice(0, 4).map((file: any, n) => {
                            const keys = Object.keys(file)

                            return <TableRow
                                key={n}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                
                                {
                                    keys.map((it, n) => 
                                        <TableCell key={it + n} style={{ cursor: 'pointer' }} component="th" scope="row">{file[it]} </TableCell>
                                    )
                                }
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={onSubmit} disabled={disabledButton} size="large" style={{ marginTop: 20 }} variant="contained">Upload</Button>
            </Box>

            <MultiSelectContactsLists
                array={lists}
                selected={selectedLists}
                setSelected={setSelectedLists}
            ></MultiSelectContactsLists>
            </Box>
        </Modal>
    )
}

function duplicates(arr: string[]) {
    const freqMap: any = {};
    const result = [];

    for (let num of arr) {
        freqMap[num] = (freqMap[num] || 0) + 1;
    }

    for (let key in freqMap) {
        if (freqMap[key] > 1 && key !== 'not_include') {
            result.push(String(key));
        }
    }

    return result;
}
