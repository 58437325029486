import { createSlice, createAsyncThunk, PayloadAction, current } from "@reduxjs/toolkit";
import { Request } from "../request";
import { toast } from "react-toastify";

export interface IResponse {
  id: string;
  name: string;
  text: string;
  createdAt: string;
}

type State = {
  response: IResponse | null;
  responses: IResponse[];
  responsesUser: IResponse[];
  limit: number;
  offset: number;
  isLoading: boolean;
};

const initialState: State = {
  response: null,
  responses: [],
  responsesUser: [],
  limit: 8,
  offset: 0,
  isLoading: false,
};


export const getResponseUser = createAsyncThunk(
  "/responses/get",
  async ({id}: any, { rejectWithValue }) => {
    try {
        const response = await Request.get(`responses/${id}`)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getResponses = createAsyncThunk(
  "/responses/getAll",
  async (_, { getState, rejectWithValue }: any) => {
    try {
        const { offset, limit } = getState().responses as State
        const response = await Request.get(`responses?offset=${offset}&limit=${limit}`)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const createResponse = createAsyncThunk(
  "/responses/create",
  async (payload: any, { rejectWithValue }) => {
    try {
        const response = await Request.post('responses', payload)
        toast('created', { type: 'success' })
        return response
    } catch (error: any) {
        toast('some error', { type: 'error' })
      return rejectWithValue(error);
    }
  }
);

export const updateResponse = createAsyncThunk(
  "/responses/update",
  async ({ id, payload }: any, { rejectWithValue }) => {
    try {
        const response = await Request.post(`responses/${id}`, payload)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const deleteResponse = createAsyncThunk(
  "/responses/delete",
  async (id: string, { rejectWithValue }) => {
    try {
        const response = await Request.delete(`responses/${id}`)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);


const templateSlice = createSlice({
  name: "responses",
  initialState,
  reducers: {
    clearResponce: (state) => {
        state.response = null
    },
    clearResponces: (state) => {
        state.responses = []
        state.offset = 0
    }
  },
  extraReducers: (builder) => {
    builder
      // get
      .addMatcher(
        (action) => action.type === getResponseUser.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === getResponseUser.fulfilled.type,
        (state, action: any) => {
            state.responsesUser = action.payload.responses
          state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === getResponseUser.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
      // get all
      .addMatcher(
        (action) => action.type === getResponses.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === getResponses.fulfilled.type,
        (state, action: PayloadAction<{responses: IResponse[]}>) => {
            state.responses = [...current(state.responses), ...action.payload.responses]
            // state.offset = current(state).offset + action.payload.responses.length || current(state).limit

            const totalLength = current(state).offset + action.payload.responses.length + 1;
            let offset = current(state).offset + action.payload.responses.length + 1;
            if (offset > totalLength)
                offset = totalLength;

            state.offset = offset || current(state).limit

            state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === getResponses.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
      // create
      .addMatcher(
        (action) => action.type === createResponse.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === createResponse.fulfilled.type,
        (state, action: PayloadAction<{response: IResponse}>) => {
            state.responses = [...current(state.responses), action.payload.response]
            state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === createResponse.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
      // update
      .addMatcher(
        (action) => action.type === updateResponse.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === updateResponse.fulfilled.type,
        (state, action: PayloadAction<{response: IResponse}>) => {
            state.responses = current(state).responses.map(response => response.id === action.payload.response.id ? action.payload.response : response)
            state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === updateResponse.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
      // delete
      .addMatcher(
        (action) => action.type === deleteResponse.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === deleteResponse.fulfilled.type,
        (state, action: PayloadAction<{id: string}>) => {
            state.responses = current(state).responses.filter(response => response.id !== action.payload.id)
            state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === deleteResponse.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
  },
});

export const { clearResponce, clearResponces } = templateSlice.actions;

export default templateSlice.reducer;
