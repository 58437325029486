import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Drawer, Container } from "@mui/material";
import { Sidebar } from "../../components/Sidebar/Sidebar";
import { Header } from "../../components/Header/Header";
import { useEffect, useState } from "react";
import './index.scss'
import { useTheme } from "../../services/theme";
import { Colors } from "../../types";
import { socket } from "../../services/socket";
import { useAuth } from "../../services/authContext";

export const Layout = () => {
    const { isUserLogged } = useAuth()
    const [colapseSideBar, setColapseSidebar] = useState<boolean>(localStorage.getItem('sidebar') === 'true' ? true : false);
    const navigate = useNavigate()
    const theme = useTheme()

    useEffect(() => {
        socket.on('test', (data) => console.log(data))
    }, [])

    useEffect(() => {
        document.body.className = 'body_overflow'
        return () => { document.body.className = document.body.className.replace("body_overflow","") }
    }, [])

    useEffect(() => {
        if (isUserLogged === false) navigate('/signin');
    }, [isUserLogged, navigate]);

    return (
        <div 
            style={{ 
                backgroundColor: theme === 'dark' ? Colors.SECONDARY_DARK : Colors.SECONDARY_LIGTH,
                color: theme === 'dark' ? Colors.MAIN_WHITE : Colors.MAIN_BLACK,
            }} 
            className="layout">
            
            <div className="layout__header">
                <Header collapsed={colapseSideBar} setShowSidebar={setColapseSidebar}></Header>
            </div>

            <div style={{ display: "flex", position: "relative", width: "100%" }}>
                <div
                    className="layout__sidebar"
                    style={{ width: colapseSideBar ? 80 : 200, }}
                >
                    <Sidebar collapsed={colapseSideBar}></Sidebar>
                </div>
                <Drawer 
                    className="layout__drawler" 
                    open={colapseSideBar} 
                    onClose={() => setColapseSidebar(false)}
                >
                    <div className="drawler__top">
                        <div>SMS</div>
                        <div style={{ cursor: 'pointer' }} onClick={() => setColapseSidebar(false)}>
                            {<FontAwesomeIcon icon={"fa-solid fa-x".split(' ') as any}></FontAwesomeIcon>}
                        </div>
                    </div>
                    <Sidebar collapsed={false}></Sidebar>
                </Drawer>

                <div
                    className="layout__content"
                    style={{ marginLeft: colapseSideBar ? 80 : 200, overflowY: 'auto' }}
                >
                    <div style={{ }}>
                        <Outlet></Outlet>
                    </div>
                </div>
            </div>
        </div>
    )
}