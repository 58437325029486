import {useCallback, useEffect, useMemo, useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography, Box, TableContainer, Table, TableHead, TableRow, TableCell, 
    TableBody, Paper, Container, Button, Stack, Modal, InputLabel, TextField, Divider, Select, FormControl, MenuItem, SelectChangeEvent, Switch } from "@mui/material"
import { useDispatch, useSelector } from "../../../../redux/store";
import { useNavigate } from "react-router-dom";
import { clearServers, getServers, updateServer, deleteServer } from "../../../../redux/slices/server";
import {getUsers} from "../../../../redux/user/user.request";

export const SendingServers = () => {
    const { servers } = useSelector(store => store.server)
    const [openModal, setOpenModal] = useState(false);
    const [horizontalScroll, setHorizontalScroll] = useState(0)
   
    const { users } = useSelector(store => store.user)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(getServers())
        return () => { dispatch(clearServers()) }
    }, [])

    const updateServerStatus = (id: string, status: boolean) => {
        dispatch(updateServer({ id, payload: { status } }))
    }

    const onDeleteServer = (id: string) => {
        dispatch(deleteServer(id))
    }
    
    const handleScroll = useCallback((event: any) => {
        const { scrollTop, scrollHeight, clientHeight, scrollLeft } = event.target;
        const isAtBottom = scrollTop + clientHeight >= scrollHeight;
    
        if(scrollLeft !== horizontalScroll) {
            setHorizontalScroll(scrollLeft)
            return
        }

        if (isAtBottom) {
            dispatch(getUsers())
        }
    }, [horizontalScroll])

    const sortedServers = useMemo(() => {
        return [...servers].sort((a, b) => a.name.localeCompare(b.name));
    }, [servers]);

    return (
        <div>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                <Typography variant="h5">Workspace</Typography><span> /sending servers</span>
            </Box>

            <Container sx={{ display: 'flex', marginTop: 4, gap: 2 }}>
               <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <Button onClick={() => navigate('add')} size="medium" variant="contained">Add New Server</Button>
               </Stack>
               {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <Button onClick={() => navigate('custom')} size="medium" variant="contained">Custom Server</Button>
               </Stack> */}
            </Container>

            <TableContainer onScroll={handleScroll} style={{ marginTop: 30, maxHeight: 'calc(100vh - 300px)' }} component={Paper}>
                <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }}>
                    <TableHead>
                    <TableRow>
                        <TableCell>NAME</TableCell>
                        <TableCell>TYPE</TableCell>
                        <TableCell>QUOTA</TableCell>
                        <TableCell>STATUS</TableCell>
                        <TableCell>ACTIONS</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {servers.map((server) => (
                        <TableRow
                            key={server.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">{server.name} </TableCell>
                            <TableCell>{server.name}</TableCell>
                            <TableCell>{server.name}</TableCell>
                            <TableCell><Switch checked={server.status} onChange={e => updateServerStatus(server.id, !server.status)}></Switch></TableCell>
                            <TableCell>
                               <Box sx={{ display: 'flex', gap: 1 }}>
                                    <div style={{ cursor: 'pointer' }} onClick={() => onDeleteServer(server.id)}>
                                        {<FontAwesomeIcon color="red" icon={"fa-solid fa-trash".split(' ') as any}></FontAwesomeIcon>}
                                    </div>
                                    <div style={{ cursor: 'pointer' }} onClick={() => navigate(`add/${server.id}`)}>
                                        {<FontAwesomeIcon color="skyblue" icon={"fa-solid fa-pen-to-square".split(' ') as any}></FontAwesomeIcon>}
                                    </div>
                               </Box>
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
