import {Routes, Route, useLocation, useNavigate, HashRouter} from 'react-router-dom'

import {SignIn} from './pages/SignIn'
import {Dashboard} from './pages/Admin/Dashboard'
import {Layout} from './pages/Admin'
import {CampaignsList} from './pages/Admin/Campaigns/list'
import {Contacts} from './pages/Admin/Contacts'
import {Workspace} from './pages/Admin/Workspace'
import {Users} from './pages/Admin/Workspace/Users'
import {CampaignsForm} from './pages/Admin/Campaigns/form'
import {Messages} from './pages/Admin/Messages'
import {Reports} from './pages/Admin/Reports'
import {ServerGroups} from './pages/Admin/Workspace/ServerGroups'
import {Keywords} from './pages/Admin/Workspace/Keywords'
import {BlockedNumbers} from './pages/Admin/Workspace/Blocked'
import {SendingServers} from './pages/Admin/Workspace/SendingServers'
import {SendingServersAdd} from './pages/Admin/Workspace/SendingServers/add'
import {SendingServersCustom} from './pages/Admin/Workspace/SendingServers/custom'
import {Lookups} from './pages/Admin/Lookups'
import {NotFound} from './pages/NotFound'
import {useAuth} from './services/authContext'
import {useEffect} from 'react'
import {Responses} from './pages/Admin/Workspace/Responses'
import {Lists} from './pages/Admin/Workspace/Lists'
import {Sim} from './pages/Admin/Workspace/Sim'
import {DashboardSim} from "./pages/Admin/Workspace/Sim/dashboard";
import {SettingsSim} from "./pages/Admin/Workspace/Sim/settings";
import {ReportsSim} from "./pages/Admin/Workspace/Sim/reports";
import RouteTracker from "./route-tracker";

export const AppRoutes = () => {
    const {isUserLogged} = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        if (!isUserLogged) {
            navigate('signin')
        }
    }, [isUserLogged])

    return (
        <>
            <Routes>
                {/* <Route path='/' element={<div></div>}></Route> */}
                <Route path='/signin' element={<SignIn />}></Route>
                <Route path='/admin' element={<Layout />}>
                    <Route index element={<Dashboard />}></Route>
                    <Route path='messages' element={<Messages />}>
                        {/* <Route index element={<Messages></Messages>}></Route>
            <Route path='create' element={<div></div>}></Route> */}
                    </Route>
                    <Route path='campaigns'>
                        <Route index element={<CampaignsList />}></Route>
                        <Route path=':id' element={<CampaignsForm />}></Route>
                    </Route>
                    <Route path='contacts' element={<Contacts />}></Route>
                    <Route path='reports' element={<Reports />}></Route>
                    <Route path='lookups' element={<Lookups />}></Route>
                    <Route path='workspace'>
                        <Route index element={<Workspace />}></Route>
                        <Route path='users' element={<Users />}></Route>
                        <Route path='serverGropus' element={<ServerGroups />}></Route>
                        <Route path='keywords' element={<Keywords />}></Route>
                        <Route path='lists' element={<Lists />}></Route>
                        <Route path='sim' element={<Sim />}>
                            <Route index element={<DashboardSim />}></Route>
                            <Route path='settings' element={<SettingsSim />}></Route>
                            <Route path='reports' element={<ReportsSim />}></Route>
                        </Route>
                        <Route path='blocked' element={<BlockedNumbers />}></Route>
                        <Route path='responses' element={<Responses />}></Route>
                        <Route path='servers'>
                            <Route index element={<SendingServers />}></Route>
                            <Route path='add' element={<SendingServersAdd />}></Route>
                            <Route path='add/:id' element={<SendingServersAdd />}></Route>
                            <Route path='custom' element={<SendingServersCustom />}></Route>
                        </Route>
                    </Route>

                </Route>
                <Route path='*' element={<NotFound />}></Route>
            </Routes>
        </>
    )
}