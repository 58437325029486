import { useEffect, useMemo, useRef, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography, Box, TableContainer, Table, TableHead, TableRow, TableCell, 
    TableBody, Paper, Container, Button, Stack, Modal, Input, InputLabel, TextField, Divider, Select, FormControl, MenuItem, ButtonGroup, OutlinedInput, SelectChangeEvent, Grid, Switch, Tabs, Tab } from "@mui/material"
import { Chat } from "./chat";
import './index.scss'
import { ChatsList } from "./list";
import { Details } from "./details";
import { autocompleteChats, clearAutocompleteChats, getChat, getChats, onNewChat, onNewSms, onOpenChat } from "../../../redux/slices/chat";
import { useDispatch, useSelector } from "../../../redux/store";
import useDebounce from "../../../services/hooks";
import { socket } from "../../../services/socket";
import { SendMessageModal } from "./utils";
import { getContact } from "../../../redux/slices/contacts";
import { useAuth } from "../../../services/authContext";
import {getResponses, getResponseUser} from "../../../redux/slices/responses";
import {getAllCampaigns} from "../../../redux/campaign/campaign.request";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export const Messages = () => {
    const { me } = useAuth()
    const { chat, chats, autocomplete, isLoadChat } = useSelector(store => store.chat)
    const { contact } = useSelector(store => store.contacts)
    const [openModal, setOpenModal] = useState(false);
    const [openModalSendSms, setOpenModalSendSms] = useState(false)
    const [tabsValue, setTabsValue] = useState(0);
    const [chatData, setChatData] = useState<{ id: string, number: string } | null>(null)
    const [text, setText] = useState('')
    const [showTrash, setShowTrash] = useState(false)
    const [showAll, setShowAll] = useState(false)
    const [onlyReplied, setOnlyReplied] = useState(false)
    const [onlyUnread, setOnlyUnread] = useState(false)
    const [sortByOldest, setSortByOldest] = useState(false)
    const [campaignId, setCampaignId] = useState('')
    const debouncedText = useDebounce(text, 500);
    const dispatch = useDispatch()

    const topRef = useRef<null | HTMLDivElement>(null)

    useEffect(() => {
        dispatch(getChats({showTrash, campaignId, showAll, onlyReplied, onlyUnread, sortByOldest}))
    }, [showTrash, campaignId, showAll, onlyReplied, onlyUnread, sortByOldest])

    useEffect(() => {
        if (text && text.startsWith('1')) dispatch(autocompleteChats(text.replace(/\s/g, '')));
        if (text && !text.startsWith('1')) dispatch(autocompleteChats(text));
        
        return () => {
          dispatch(clearAutocompleteChats());
        };
    }, [debouncedText]);

    useEffect(() => {
        if(chatData?.id) {
            dispatch(getChat(chatData?.id))
            dispatch(getContact(chatData.number))
            dispatch(onOpenChat(chatData?.id))
        }
    }, [chatData?.id])

    const chats_data = useMemo(() => autocomplete || chats, [autocomplete, chats, debouncedText])
    // console.log("autoComplete",autocomplete);
    // console.log("chatsdata",chats_data);
    const scrollToBottom = () => {
        topRef.current?.scrollIntoView({ block: 'end' })
    }
  
    useEffect(() => {
      scrollToBottom()
    }, );
      
    useEffect(() => {
        document.body.className = 'body_overflow_y_hidden'
        return () => { document.body.className = document.body.className.replace("body_overflow_y_hidden","") }
    }, [])

    useEffect(() => {
        dispatch(getAllCampaigns())
        dispatch(getResponseUser({id: me?.id}))

        socket.on('inbound_sms', (data): any => {
          dispatch(onNewSms(data))
        })
        
        
    
        socket.on('inbound_chat', (data): any => {
          if(data.ids.includes(me?.id) || me?.role === 'admin') dispatch(onNewChat(data.chat))
        })
    
        return () => { socket.off('inbound_sms'); socket.off('inbound_chat') }
    }, [])

    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;
        
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
            style={{
                height: 'calc(100vh - 280px)' 
            }}
          >
            {value === index && <>{children}</>}
          </div>
        );
    }

    return (
        <div>
            <Box ref={topRef} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h5">Messages</Typography>
                <Button onClick={() => setOpenModalSendSms(true)} variant="contained">Send Message</Button>
            </Box>

            {/* <Stack style={{ marginTop: 20 }} direction={{ xs: 'column', sm: 'row' }} gap={2}  justifyContent={'space-between'}>
                <Button onClick={() => setOpenModalSendSms(true)} variant="contained">Send Message</Button>

                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Button onClick={() => setOpenModalSendSms(true)} variant="contained">Send Message</Button>
                </Box>
            </Stack> */}

                {/** Desktop */}
            <div className="messages__desktop__container">
                <Box sx={{ width: '40%', height: 'calc(100vh - 170px)', borderRight: '1px solid gray' }}>
                    <ChatsList
                        showTrash={showTrash} 
                        setShowTrash={setShowTrash} 
                        showAll={showAll} 
                        setShowAll={setShowAll} 
                        text={text} 
                        setText={setText} 
                        setChatId={setChatData} 
                        chats={chats_data}
                        campaignId={campaignId}
                        setCampaignId={setCampaignId}
                        onlyReplied={onlyReplied}
                        setOnlyReplied={setOnlyReplied}
                        onlyUnread={onlyUnread}
                        setOnlyUnread={setOnlyUnread}
                        sortByOldest={sortByOldest}
                        setSortByOldest={setSortByOldest}
                    ></ChatsList>
                </Box>

                { chatData?.id || isLoadChat ?
                    <div className="messages__desktop__content">
                        <div className="messages__desktop__buttons">
                            <Tabs
                                value={tabsValue}
                                onChange={(e, v) => setTabsValue(v)}
                                aria-label="basic tabs"
                            >
                                { chatData && <Tab label="Chat" />}
                                <Tab label="User details" />
                            
                            </Tabs>
                            <Button
                                className="message__desktop__close"
                                sx={{
                                    marginTop: "10px",
                                    marginBottom: "4px",
                                    marginRight: "4px",
                                    marginLeft: "8px"
                                }}
                                variant="contained"
                                onClick={() => setChatData(null)}
                            >
                                Close
                            </Button>
                        </div>
                        <TabPanel value={tabsValue} index={0}>
                            {chatData && <Chat chat={chat}></Chat>}
                        </TabPanel>
                        <TabPanel value={tabsValue} index={1}>
                            { chatData && contact && <Details contact={contact}></Details>}
                        </TabPanel>
                    </div> 
                    : <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '60%' }}>Select Chat</Box>
                }
            </div>

                {/** Mobile */}
            <div className="messages__mobile__container">
                <div className="messages__mobile__content">

                    <Tabs
                        value={tabsValue}
                        onChange={(e, v) => setTabsValue(v)}
                        aria-label="basic tabs"
                    >
                        <Tab style={{ zIndex: 99 }} label="List" />
                        { chatData && <Tab style={{ zIndex: 99 }} label="Chat" />}
                        { chatData && <Tab style={{ zIndex: 99 }} label="Details" />}
                    
                    </Tabs>
                    {
                        chatData && <Button
                            className="message__close"
                            sx={{
                                marginTop: "10px",
                                marginBottom: "4px",
                                marginRight: "16px",
                                marginLeft: "8px",
                                zIndex: 99,
                            }}
                            variant="contained"
                            //  onClick={() => setIsOpenChat(null)}
                        >
                            Close
                        </Button>
                    }
                </div>
                <TabPanel value={tabsValue} index={0}>
                    <ChatsList 
                        showTrash={showTrash} 
                        setShowTrash={setShowTrash} 
                        showAll={showAll} 
                        setShowAll={setShowAll} 
                        text={text} 
                        setText={setText} 
                        setChatId={setChatData} 
                        chats={chats_data}
                        campaignId={campaignId}
                        setCampaignId={setCampaignId}
                        onlyReplied={onlyReplied}
                        setOnlyReplied={setOnlyReplied}
                        onlyUnread={onlyUnread}
                        setOnlyUnread={setOnlyUnread}
                        sortByOldest={sortByOldest}
                        setSortByOldest={setSortByOldest}
                    ></ChatsList>
                </TabPanel>
                <TabPanel value={tabsValue} index={1}>
                    {chat && <Chat chat={chat}></Chat>}
                </TabPanel>
                <TabPanel value={tabsValue} index={2}>
                    { chatData && contact && <Details contact={contact}></Details>}
                </TabPanel>
            </div>

            { openModalSendSms && <SendMessageModal open={openModalSendSms} close={() => setOpenModalSendSms(false)}></SendMessageModal> }
        </div>
    )
}
